@use 'vars' as *;

.news-page{
	padding-top: clamp(10em,20vw,26.5em);
	padding-bottom: clamp(5em,10vw,12em);
	position: relative;
	z-index: 2;
	&__ttl{
		max-width: 540px;
		margin: 0 auto clamp(3em,10vw,13.7em);
		@include mq{
			max-width: 300px;
		}
	}
}

.tabs-panel{
	@include flex(between,center);
	max-width: 900px;
	margin: 0 auto;
	@include mq(tb){
		gap: 0 10px;
		width: 95%;
	}
	li{
		max-width: 400px;
		width: 50%;
		a,span{
			width: 100%;
			height: 55px;
			@include flex(center, center);
			font-family: $font-jp;
			@include font-size(24);
			font-weight: 700;
			color: #1f4073;
			border: 3px solid #557393;
			border-bottom: none;
			border-radius: 4px 4px 0 0;
			cursor: pointer;
			@include mq{
				width: 100%;
				@include font-size(18);
				height: 40px;
				border-width: 2px;
			}
		}
		&.active{
			a,span{
				color: #fff;
				background: #1f4073;
				border-color: #1f4073;
			}
		}
	}
}

.tab-contents{
	display: none;
	border-top: 3px solid #1f4073;
	//padding: clamp(2.5em,7vw,8em) clamp(2.5em,7vw,9em);
	padding: clamp(.5em,1vw,1em) clamp(2.5em,7vw,9em);
	background: rgba(#fff, 0.6);
	@include mq{
		border-width: 2px;
	}
	&.active{
		display: block;
	}
}


.news-block{
	margin-bottom: clamp(3em,7vw,9em);
	&__item{
		//margin-bottom: 60px;
		//padding-bottom: 60px;
		padding: 60px 0;
		border-bottom: 1px dotted #1f4073;
		@include flex(0, center, wrap);
		&:last-child{
			margin-bottom: 0;
		}
		@include mq{
			//padding-bottom: 30px;
			//margin-bottom: 30px;
			padding: 30px 0;
		}
		@include p-after(100%, 1px){
			background: url(../img/news/line.png) repeat-x center/2px 1px;
			bottom: 0;
			left: 0;
		}
	}
	&__img{
		width: 380px;
		border: 3px solid #1f4073;
		border-radius: 10px;
		overflow: hidden;
		position: relative;
		@include mq(tb){
			width: 280px;
		}
		@include mq{
			width: 250px;
		}
		@include mq(sm){
			width: 100%;
		}
		@include p-after(26px, 26px){
			background: url(../img/news/icon-arrow.png) no-repeat center/100%;
			bottom: 10px;
			right: 10px;
		}
		&-thumb{
			@include aspect-ratio(76, 56);
		}
	}
	
	&__title{
		@include fts(22, 40);
		font-weight: 500;
		margin-bottom: 25px;
		@include mq{
			@include font-size(16);
			margin-bottom: 15px;
		}
	}
	&__ct{
		width: calc(100% - 380px);
		padding-left: clamp(3em,5vw,6em);
		@include mq(tb){
			width: calc(100% - 280px);
		}
		@include mq{
			width: calc(100% - 250px);
		}
		@include mq(sm){
			width: 100%;
			padding-left: 0;
			padding-top: 15px;
		}
		time{
			@include font-size(20);
			font-weight: 600;
			color: #1f4073;
			display: block;
			margin-bottom: 23px;
			@include mq{
				@include font-size(16);
				margin-bottom: 10px;
			}
		}
		.c-btn__02{
			padding-right: 19px;
			&::before, &:after{
				height: 1px;
				background: #000000;
			}
			img{
				height: 12px;
			}
		}
	}
}

.wp-pagenavi{
	position: relative;
	@include flex(center, center);
	.page, .current{
		display: none;
	}
	.pages{
		order: 2;
		font-family: $font-jp;
		@include font-size(30);
		font-weight: 700;
		line-height: 1;
		letter-spacing: 1px;
		color: #1f4073;
		padding: 0 40px;
		@include mq{
			@include font-size(24);
			padding: 0 30px;
		}
		span{
			@include font-size(20);
			margin-left: 2px;
			@include mq{
				@include font-size(16);
			}
		}
	}
	.previouspostslink, .nextpostslink{
		width: 100px;
		position: relative;
		padding-bottom: 10px;
		@include mq{
			width: 80px;
			padding-bottom: 5px;
		}
		img{
			height: 11px;
		}
		@include p-after(10px,2px){
			background: #1F4073;
			right: -2px;
			transform: rotate(48deg);
			bottom: 5px;
			width: 13px;
			transition: all 0.3s;
		}
		@include p-before(100%,2px){
			background: #1F4073;
			right: 0;
			bottom: 0;
			left: 0;
			transform: scale(1);
			transition: all 0.3s;
			transform-origin: right;
		}
	}
	.nextpostslink{
		position: absolute;
		top: 0;
		right: 50%;
		margin-right: -180px;
		order: 3;
		text-align: left;
		@include mq{
			margin-right: -140px;
		}
		@include hover{
			opacity: 1;
			&::before{
				width: calc(100% + 10px);
				transition: all 0.3s;
			}
			&::after{
				right: -13px;
				transition: all 0.3s;
			}
		}
	}
	.previouspostslink{
		position: absolute;
		top: 0;
		left: 50%;
		margin-left: -180px;
		text-align: right;
		order: 1;
		@include mq{
			margin-left: -140px;
		}
		&:after{
			right: auto;
			left: -2px;
			transform: rotate(-48deg);
		}
		@include hover{
			opacity: 1;
			&::before{
				transform: scale(1.1);
			}
			&::after{
				left: -12px;
				transition: all 0.3s;
			}
		}
	}
}

.schedule-block{
	@include flex(0,0,wrap);
	padding: 60px 0;
	@include mq{
		padding: 30px 0;
	}
	&__sidebar{
		width: 250px;
		@include mq{
			width: 100%;
		}
		&-list {
			@include mq{
				width: 100%;
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 10px;
				margin-bottom: 30px;
			}
			
			li{
				margin-bottom: 30px;
				@include mq{
					margin-bottom: 0;
				}
				&:last-of-type{
					margin-bottom: 0;
				}
				a{
					@include flex(center, center);
					height: 50px;
					color: #1f4073;
					background: #fff;
					border: 2px solid #1f4073;
					border-radius: 4px;
					@include font-size(20);
					font-weight: 700;
					@include mq{
						height: 40px;
						@include font-size(17);
					}
				}
				&.is-active{
					a{
						background: #1f4073;
						color: #fff;
					}
				}
			}
		}
	}
	&__content{
		width: calc(100% - 250px);
		padding-left: clamp(3em,7vw,9em);
		@include mq{
			padding-left: 0;
			width: 100%;
		}
		.c-btn__02{

			&::before{
				position: relative;
			}
		}
	}
	&__btn{
		position: relative;
		display: inline-flex;
		align-items: flex-end;
		gap: 0 10px;
		@include p-after(50px, 2px){
			background: #1F4073;
			position: relative;
			transition: all 0.3s;
			transform: scale(1);
			transform-origin: left;
		}
		@include p-before(10px,2px){
			background: #1F4073;
			right: -2px;
			transform: rotate(48deg);
			bottom: 5px;
			width: 13px;
			transition: all 0.3s;
		}
		img{
			height: 13px;
		}
		&:hover{
			&:after{
				transform: scale(1.2);
			}
			&::before{
				right: -13px;
			}
		}
	}
	&__list{
		margin-bottom: 50px;
		@include mq{
			margin-bottom: 30px;
		}
		&-item{
			position: relative;
			&::before, &:after{
				content: "";
				display: block;
				width: 100%;
				height: 1px;
				background: url(../img/news/line.png) repeat-x center/2px 1px;
				left: 0;
			}
			&::before{
				display: none;
			}
			&::after{
				bottom: 0;
			}
			&:nth-child(1){
				&::before{
					display: block;
				}
			}
			&:nth-child(even){ 
				.schedule-block__list-link{
					background: #f2f4f7;
				}
			}
		}
		&-link{
			@include flex(0,center);
			padding: 28px 40px;
			background: #fff;
			@include mq(xl){
				flex-wrap: wrap;
				padding: 15px;
			}
		}
		&-cate{
			width: 80px;
			@include font-size(13);
			color: #fff;
			background: #cc223a;
			font-weight: 500;
			display: inline-block;
			padding: 7px 0 5px;
			text-align: center;
			margin-right: 20px;
			line-height: 1;
		}
		&-time{
			@include font-size(14);
			font-weight: 500;
			color: #1f4073;
		}
		&-ttl{
			@include font-size(16);
			color: #111;
			padding-left: 30px;
			@include mq(xl){
				padding-left: 0;
				padding-top: 15px;
				width: 100%;
			}
		}
	}
}

#zoomback {
	position:fixed;
	top:0;
	z-index: 999;
	width:100vw;
	height:100vh;
	background-color:rgba(0,0,0,0.8);
	display:flex;
	justify-content:center;
	align-items:center;
	overflow: hidden;
	display: none;
}
#zoomimg {
	width:90%;
	min-width: 300px;
	max-width: 1000px;
	img {
		width: 100%;
	}
}
.deka {
	animation:deka 0.3s ease-out;
}
@keyframes deka {
	from {
		transform:scale(0.9);
	}
}
.zoomimg {
	display: none;
	width: 100%;
}
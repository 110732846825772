@charset "UTF-8";
/* ====================================================
Font
==================================================== */
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+JP:wght@400;500;600;700&display=swap");
/* ====================================================
reset style
==================================================== */
html {
  overflow-y: scroll;
  overflow-x: hidden;
  font-size: 10px; /* Base font size, change to 14px or 16px if needed */
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset, img {
  border: 0;
}

img {
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

address, caption, cite, code, dfn, var {
  font-style: normal;
  font-weight: normal;
}

li {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

q:before, q:after {
  content: "";
}

abbr, acronym {
  border: 0;
  font-variant: normal;
}

sup {
  vertical-align: text-top;
}

sub {
  vertical-align: text-bottom;
}

input, textarea, select, button {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/*to enable resizing for IE*/
input, textarea, select {
  *font-size: 100%;
}

/*because legend doesn't inherit in IE */
legend {
  color: #000;
}

del, ins {
  text-decoration: none;
}

main {
  display: block;
}

section {
  position: relative;
}

/* ====================================================
Media Quary
==================================================== */
/* ====================================================
// flexセット
==================================================== */
/* ====================================================
画像などを縦横比を維持したままレスポンシブ対応
*画像やGoogle Map、YouTubeの埋め込みなどを縦横比を維持したままレスポンシブ対応
.photo {
  // 画像を16:9にトリミングして縦横比を常に保つ
  @include aspect-ratio(16, 9);

  & > .image {
    @include object-fit(cover);
  }
}
==================================================== */
@media screen and (min-width: 835px) {
  .mbpc-1 {
    margin-bottom: 1px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-1 {
    margin-bottom: 1px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-2 {
    margin-bottom: 2px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-2 {
    margin-bottom: 2px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-3 {
    margin-bottom: 3px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-3 {
    margin-bottom: 3px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-4 {
    margin-bottom: 4px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-4 {
    margin-bottom: 4px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-5 {
    margin-bottom: 5px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-5 {
    margin-bottom: 5px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-6 {
    margin-bottom: 6px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-6 {
    margin-bottom: 6px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-7 {
    margin-bottom: 7px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-7 {
    margin-bottom: 7px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-8 {
    margin-bottom: 8px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-8 {
    margin-bottom: 8px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-9 {
    margin-bottom: 9px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-9 {
    margin-bottom: 9px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-10 {
    margin-bottom: 10px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-10 {
    margin-bottom: 10px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-11 {
    margin-bottom: 11px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-11 {
    margin-bottom: 11px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-12 {
    margin-bottom: 12px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-12 {
    margin-bottom: 12px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-13 {
    margin-bottom: 13px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-13 {
    margin-bottom: 13px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-14 {
    margin-bottom: 14px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-14 {
    margin-bottom: 14px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-15 {
    margin-bottom: 15px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-15 {
    margin-bottom: 15px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-16 {
    margin-bottom: 16px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-16 {
    margin-bottom: 16px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-17 {
    margin-bottom: 17px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-17 {
    margin-bottom: 17px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-18 {
    margin-bottom: 18px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-18 {
    margin-bottom: 18px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-19 {
    margin-bottom: 19px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-19 {
    margin-bottom: 19px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-20 {
    margin-bottom: 20px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-20 {
    margin-bottom: 20px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-21 {
    margin-bottom: 21px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-21 {
    margin-bottom: 21px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-22 {
    margin-bottom: 22px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-22 {
    margin-bottom: 22px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-23 {
    margin-bottom: 23px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-23 {
    margin-bottom: 23px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-24 {
    margin-bottom: 24px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-24 {
    margin-bottom: 24px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-25 {
    margin-bottom: 25px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-25 {
    margin-bottom: 25px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-26 {
    margin-bottom: 26px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-26 {
    margin-bottom: 26px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-27 {
    margin-bottom: 27px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-27 {
    margin-bottom: 27px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-28 {
    margin-bottom: 28px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-28 {
    margin-bottom: 28px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-29 {
    margin-bottom: 29px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-29 {
    margin-bottom: 29px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-30 {
    margin-bottom: 30px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-30 {
    margin-bottom: 30px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-31 {
    margin-bottom: 31px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-31 {
    margin-bottom: 31px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-32 {
    margin-bottom: 32px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-32 {
    margin-bottom: 32px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-33 {
    margin-bottom: 33px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-33 {
    margin-bottom: 33px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-34 {
    margin-bottom: 34px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-34 {
    margin-bottom: 34px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-35 {
    margin-bottom: 35px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-35 {
    margin-bottom: 35px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-36 {
    margin-bottom: 36px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-36 {
    margin-bottom: 36px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-37 {
    margin-bottom: 37px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-37 {
    margin-bottom: 37px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-38 {
    margin-bottom: 38px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-38 {
    margin-bottom: 38px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-39 {
    margin-bottom: 39px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-39 {
    margin-bottom: 39px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-40 {
    margin-bottom: 40px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-40 {
    margin-bottom: 40px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-41 {
    margin-bottom: 41px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-41 {
    margin-bottom: 41px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-42 {
    margin-bottom: 42px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-42 {
    margin-bottom: 42px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-43 {
    margin-bottom: 43px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-43 {
    margin-bottom: 43px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-44 {
    margin-bottom: 44px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-44 {
    margin-bottom: 44px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-45 {
    margin-bottom: 45px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-45 {
    margin-bottom: 45px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-46 {
    margin-bottom: 46px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-46 {
    margin-bottom: 46px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-47 {
    margin-bottom: 47px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-47 {
    margin-bottom: 47px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-48 {
    margin-bottom: 48px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-48 {
    margin-bottom: 48px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-49 {
    margin-bottom: 49px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-49 {
    margin-bottom: 49px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-50 {
    margin-bottom: 50px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-50 {
    margin-bottom: 50px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-51 {
    margin-bottom: 51px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-51 {
    margin-bottom: 51px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-52 {
    margin-bottom: 52px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-52 {
    margin-bottom: 52px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-53 {
    margin-bottom: 53px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-53 {
    margin-bottom: 53px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-54 {
    margin-bottom: 54px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-54 {
    margin-bottom: 54px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-55 {
    margin-bottom: 55px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-55 {
    margin-bottom: 55px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-56 {
    margin-bottom: 56px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-56 {
    margin-bottom: 56px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-57 {
    margin-bottom: 57px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-57 {
    margin-bottom: 57px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-58 {
    margin-bottom: 58px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-58 {
    margin-bottom: 58px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-59 {
    margin-bottom: 59px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-59 {
    margin-bottom: 59px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-60 {
    margin-bottom: 60px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-60 {
    margin-bottom: 60px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-61 {
    margin-bottom: 61px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-61 {
    margin-bottom: 61px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-62 {
    margin-bottom: 62px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-62 {
    margin-bottom: 62px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-63 {
    margin-bottom: 63px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-63 {
    margin-bottom: 63px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-64 {
    margin-bottom: 64px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-64 {
    margin-bottom: 64px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-65 {
    margin-bottom: 65px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-65 {
    margin-bottom: 65px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-66 {
    margin-bottom: 66px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-66 {
    margin-bottom: 66px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-67 {
    margin-bottom: 67px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-67 {
    margin-bottom: 67px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-68 {
    margin-bottom: 68px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-68 {
    margin-bottom: 68px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-69 {
    margin-bottom: 69px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-69 {
    margin-bottom: 69px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-70 {
    margin-bottom: 70px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-70 {
    margin-bottom: 70px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-71 {
    margin-bottom: 71px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-71 {
    margin-bottom: 71px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-72 {
    margin-bottom: 72px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-72 {
    margin-bottom: 72px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-73 {
    margin-bottom: 73px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-73 {
    margin-bottom: 73px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-74 {
    margin-bottom: 74px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-74 {
    margin-bottom: 74px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-75 {
    margin-bottom: 75px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-75 {
    margin-bottom: 75px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-76 {
    margin-bottom: 76px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-76 {
    margin-bottom: 76px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-77 {
    margin-bottom: 77px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-77 {
    margin-bottom: 77px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-78 {
    margin-bottom: 78px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-78 {
    margin-bottom: 78px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-79 {
    margin-bottom: 79px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-79 {
    margin-bottom: 79px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-80 {
    margin-bottom: 80px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-80 {
    margin-bottom: 80px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-81 {
    margin-bottom: 81px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-81 {
    margin-bottom: 81px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-82 {
    margin-bottom: 82px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-82 {
    margin-bottom: 82px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-83 {
    margin-bottom: 83px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-83 {
    margin-bottom: 83px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-84 {
    margin-bottom: 84px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-84 {
    margin-bottom: 84px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-85 {
    margin-bottom: 85px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-85 {
    margin-bottom: 85px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-86 {
    margin-bottom: 86px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-86 {
    margin-bottom: 86px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-87 {
    margin-bottom: 87px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-87 {
    margin-bottom: 87px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-88 {
    margin-bottom: 88px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-88 {
    margin-bottom: 88px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-89 {
    margin-bottom: 89px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-89 {
    margin-bottom: 89px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-90 {
    margin-bottom: 90px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-90 {
    margin-bottom: 90px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-91 {
    margin-bottom: 91px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-91 {
    margin-bottom: 91px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-92 {
    margin-bottom: 92px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-92 {
    margin-bottom: 92px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-93 {
    margin-bottom: 93px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-93 {
    margin-bottom: 93px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-94 {
    margin-bottom: 94px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-94 {
    margin-bottom: 94px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-95 {
    margin-bottom: 95px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-95 {
    margin-bottom: 95px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-96 {
    margin-bottom: 96px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-96 {
    margin-bottom: 96px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-97 {
    margin-bottom: 97px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-97 {
    margin-bottom: 97px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-98 {
    margin-bottom: 98px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-98 {
    margin-bottom: 98px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-99 {
    margin-bottom: 99px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-99 {
    margin-bottom: 99px !important;
  }
}
@media screen and (min-width: 835px) {
  .mbpc-100 {
    margin-bottom: 100px !important;
  }
}
@media screen and (max-width: 834px) {
  .mbsp-100 {
    margin-bottom: 100px !important;
  }
}
.mb0 {
  margin-bottom: 0 !important;
}

html {
  overflow-y: scroll;
}
html.noscroll {
  overflow-y: hidden;
}

body {
  color: #111111;
  font-family: "IBM Plex Sans JP", sans-serif;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  line-height: 1.3;
  overflow-x: clip;
  position: relative;
}
body:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background: url("../img/index/bg-mv.png") center no-repeat;
  background-size: cover;
  pointer-events: none;
}
body:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 66%;
  height: 39vw;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1;
  background: url("../img/common/img-earth.png") center no-repeat;
  background-size: contain;
}
@media screen and (max-width: 834px) {
  body:after {
    width: 76%;
    height: 45vw;
  }
}

.bg-main {
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  position: fixed;
  background: url("../img/index/bg-main.jpg") center repeat;
  background-size: contain;
  z-index: 0;
}

a {
  text-decoration: none;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media (any-hover: hover) {
  a:hover {
    opacity: 0.7;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  a {
    display: inline-block;
  }
}
@media screen and (min-width: 835px) {
  a[href^=tel] {
    pointer-events: none;
  }
}
@media screen and (max-width: 834px) {
  a {
    -webkit-tap-highlight-color: transparent;
  }
}

@media screen and (max-width: 834px) {
  .pc {
    display: none !important;
  }
}
@media screen and (max-width: 1024px) {
  .pc-tb {
    display: none !important;
  }
}
@media screen and (max-width: 990px) {
  .pc-lg {
    display: none !important;
  }
}
@media screen and (max-width: 640px) {
  .pc-sm {
    display: none !important;
  }
}
@media screen and (max-width: 374px) {
  .pc-xs {
    display: none !important;
  }
}

@media screen and (min-width: 835px) {
  .sp {
    display: none !important;
  }
}
@media screen and (min-width: 1025px) {
  .sp-tb {
    display: none !important;
  }
}
@media screen and (min-width: 991px) {
  .sp-lg {
    display: none !important;
  }
}
@media screen and (min-width: 641px) {
  .sp-sm {
    display: none !important;
  }
}

.u-fn-hover {
  display: inline-block;
}
@media screen and (min-width: 835px) {
  .u-fn-hover {
    transition: all 0.2s;
  }
  .u-fn-hover:hover {
    opacity: 0.7;
  }
}

.post-style ul, .post-style ol {
  padding-left: 1.6rem;
}
.post-style ul li {
  list-style-type: disc;
}
.post-style ol li {
  list-style-type: decimal;
}
.post-style h1, .post-style h2, .post-style h3, .post-style h4, .post-style h5, .post-style h6 {
  font-size: large;
  font-weight: 700;
}

.c-fw--lt {
  font-weight: 300 !important;
}
.c-fw--rg {
  font-weight: 400 !important;
}
.c-fw--md {
  font-weight: 500 !important;
}
.c-fw--sbd {
  font-weight: 600 !important;
}
.c-fw--bd {
  font-weight: 700 !important;
}
.c-fw--bk {
  font-weight: 900 !important;
}

.c-al--c {
  text-align: center !important;
}
.c-al--r {
  text-align: right !important;
}
.c-al--l {
  text-align: left !important;
}

.c-bd--b {
  border: 2px solid #355381;
}

.c-pull--left {
  margin-left: calc((100vw - 100%) / 2 * -1);
}
.c-pull--right {
  margin-right: calc((100vw - 100%) / 2 * -1);
}

/* ====================================================
Component
==================================================== */
.c-txt__01 {
  line-height: 2;
  font-size: 1.8rem;
}
@media screen and (max-width: 1245px) {
  .c-txt__01 {
    font-size: 1.7rem;
  }
}
@media screen and (max-width: 834px) {
  .c-txt__01 {
    font-size: 1.4rem;
  }
}
.c-txt__02 {
  line-height: 1.75;
  font-size: 1.6rem;
}
@media screen and (max-width: 1245px) {
  .c-txt__02 {
    line-height: 1.6;
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 834px) {
  .c-txt__02 {
    font-size: 1.3rem;
  }
}

.c-ttl__01 {
  margin-bottom: 36px;
}
@media screen and (max-width: 1245px) {
  .c-ttl__01 {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 834px) {
  .c-ttl__01 {
    text-align: center;
    margin-bottom: 24px;
  }
}
.c-ttl__01 img {
  height: 130px;
  width: auto;
}
@media screen and (max-width: 1245px) {
  .c-ttl__01 img {
    height: 85px;
  }
}
@media screen and (max-width: 834px) {
  .c-ttl__01 img {
    height: 52px;
  }
}
.c-ttl__02 {
  margin-bottom: 41px;
}
@media screen and (max-width: 1245px) {
  .c-ttl__02 {
    margin-bottom: 20px;
  }
}
.c-ttl__02 img {
  height: 56px;
  width: auto;
}
@media screen and (max-width: 1245px) {
  .c-ttl__02 img {
    height: 45px;
  }
}
@media screen and (max-width: 834px) {
  .c-ttl__02 img {
    height: 42px;
  }
}

.c-btn__01 {
  position: relative;
  max-width: 302px;
  height: 80px;
  width: 100%;
  color: #fff;
  padding: 12px 31px;
  letter-spacing: 0.5px;
  line-height: 1;
  font-size: 1.4rem;
  background: linear-gradient(180deg, #1f4073, #2e699f);
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}
@media screen and (max-width: 834px) {
  .c-btn__01 {
    letter-spacing: 0;
    height: 65px;
    max-width: 275px;
    margin: 0 auto;
    padding: 9px 24px;
  }
}
.c-btn__01 span {
  display: block;
  margin-top: 8px;
  width: 100%;
}
@media screen and (max-width: 834px) {
  .c-btn__01 span {
    margin-top: 6px;
  }
}
.c-btn__01 img {
  width: 100px;
}
@media screen and (max-width: 834px) {
  .c-btn__01 img {
    width: 95px;
  }
}
.c-btn__01:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 50px;
  height: 2px;
  background: #fff;
  right: 30px;
  top: 56%;
  transform: translateY(-50%);
  transition: all 0.3s;
}
@media screen and (max-width: 834px) {
  .c-btn__01:before {
    width: 30px;
    top: 55%;
    right: 24px;
  }
}
.c-btn__01:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 10px;
  height: 2px;
  background: #fff;
  right: 30px;
  top: 50%;
  transform: rotate(41deg) translateY(-50%);
  top: calc(53% - 2px);
  width: 13px;
  transition: all 0.3s;
}
@media screen and (max-width: 834px) {
  .c-btn__01:after {
    width: 10px;
    right: 24px;
  }
}
@media (any-hover: hover) {
  .c-btn__01:hover {
    opacity: 1;
  }
  .c-btn__01:hover::before {
    transition: all 0.3s;
    width: 60px;
    right: 20px;
  }
  .c-btn__01:hover::after {
    right: 20px;
    transition: all 0.3s;
  }
}
.c-btn__02 {
  line-height: 1;
  display: inline-block;
  padding-right: 41px;
  padding-bottom: 5px;
  position: relative;
}
.c-btn__02:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 10px;
  height: 2px;
  background: #1F4073;
  right: -2px;
  transform: rotate(48deg);
  bottom: 5px;
  width: 13px;
  transition: all 0.3s;
}
.c-btn__02:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 2px;
  background: #1F4073;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all 0.3s;
}
.c-btn__02 img {
  width: auto;
  height: 10px;
}
@media (any-hover: hover) {
  .c-btn__02:hover {
    opacity: 1;
  }
  .c-btn__02:hover::before {
    width: calc(100% + 10px);
    transition: all 0.3s;
  }
  .c-btn__02:hover::after {
    right: -13px;
    transition: all 0.3s;
  }
}
.c-btn__02--small img {
  height: 12px;
}

.js-scrollin {
  opacity: 0;
  -webkit-transform: translate(0, 50px);
  -ms-transform: translate(0, 50px);
  transform: translate(0, 50px);
}
.js-scrollin.active {
  opacity: 1;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -o-transition: 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.inner {
  display: block;
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
}
.inner--1500 {
  max-width: 1500px;
}

.ov {
  overflow: hidden;
}

.no-bg:after {
  display: none;
}

.form-control {
  display: block;
  width: 100%;
  height: 70px;
  background: white;
  font-weight: 500;
  padding: 10px 15px;
  font-size: 1.8rem;
  border-radius: 5px;
  border: none;
}
@media screen and (max-width: 834px) {
  .form-control {
    font-size: 1.6rem;
    height: 50px;
  }
}
.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}
.form-control--short {
  max-width: 600px;
}
.form-control--textarea {
  padding: 20px;
  height: 300px;
  resize: vertical;
}
@media screen and (max-width: 834px) {
  .form-control--textarea {
    height: 200px;
  }
}

.js-locked {
  overflow: hidden;
  -ms-touch-action: none;
  touch-action: none;
}
.js-locked body {
  -webkit-overflow-scrolling: auto;
}

.sticky {
  position: sticky;
  top: 0;
}

.page-parts .mv-index__right {
  height: inherit;
}

/* ====================================================
Header
==================================================== */
.main-header__content {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  z-index: 99;
  background: url("../img/index/bg-main.jpg") center repeat;
  background-size: contain;
  background-attachment: fixed;
  overflow-y: auto;
}
.main-header__logo {
  width: 393px;
  margin-bottom: 121px;
  display: block;
}
@media screen and (max-width: 1440px) {
  .main-header__logo {
    width: 350px;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 1245px) {
  .main-header__logo {
    width: 280px;
  }
}
@media screen and (max-width: 834px) {
  .main-header__logo {
    width: 230px;
  }
}
.main-header__logo img {
  width: 100%;
}
.main-header__logo-child {
  position: absolute;
  top: 22px;
  left: calc((100% - 1500px) / 2);
  width: 260px;
  display: block;
  z-index: 10;
}
@media screen and (max-width: 1500px) {
  .main-header__logo-child {
    left: 5%;
  }
}
@media screen and (max-width: 834px) {
  .main-header__logo-child {
    width: 130px;
    top: 15px;
  }
}
.main-header__navGlobal {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.main-header__navGlobal-left {
  width: 50%;
  padding: 47px 100px 50px;
}
@media screen and (max-width: 1440px) {
  .main-header__navGlobal-left {
    padding: 35px 80px;
  }
}
@media screen and (max-width: 1245px) {
  .main-header__navGlobal-left {
    padding: 35px 30px;
  }
}
@media screen and (max-width: 834px) {
  .main-header__navGlobal-left {
    width: 100%;
    padding: 75px 30px;
  }
}
.main-header__navGlobal-right {
  width: 50%;
}
@media screen and (max-width: 834px) {
  .main-header__navGlobal-right {
    display: none;
  }
}
.main-header__navGlobal-right img {
  width: 100%;
  object-fit: cover;
  height: 100vh;
  height: 100dvh;
}
.main-header__menu {
  margin-bottom: 96px;
}
@media screen and (max-width: 1440px) {
  .main-header__menu {
    margin-bottom: 40px;
  }
}
.main-header__menu-item:not(:last-child) {
  margin-bottom: 40px;
}
@media screen and (max-width: 1440px) {
  .main-header__menu-item:not(:last-child) {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 834px) {
  .main-header__menu-item:not(:last-child) {
    margin-bottom: 30px;
  }
}
.main-header__menu-item a {
  display: block;
}
.main-header__menu-item a img {
  height: 26px;
  width: auto;
}
@media screen and (max-width: 1440px) {
  .main-header__menu-item a img {
    height: 22px;
  }
}
@media screen and (max-width: 1245px) {
  .main-header__menu-item a img {
    height: 20px;
  }
}
@media screen and (max-width: 834px) {
  .main-header__menu-item a img {
    height: 18px;
  }
}
.main-header__share {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  gap: 0 28px;
}
@media screen and (max-width: 834px) {
  .main-header__share {
    gap: 0 30px;
  }
}
.main-header__share-item a {
  display: block;
  color: #355381;
  font-size: 3.7rem;
}
@media screen and (max-width: 1440px) {
  .main-header__share-item a {
    font-size: 3.2rem;
  }
}
@media screen and (max-width: 834px) {
  .main-header__share-item a {
    font-size: 2.8rem;
  }
}
.main-header__mobile-icon {
  position: fixed;
  top: 0;
  right: 0;
  width: 90px;
  height: 90px;
  background: #1f4073;
  z-index: 102;
  cursor: pointer;
}
@media screen and (max-width: 1440px) {
  .main-header__mobile-icon {
    width: 60px;
    height: 60px;
  }
}
@media screen and (max-width: 834px) {
  .main-header__mobile-icon {
    width: 50px;
    height: 50px;
  }
}
.main-header__mobile-icon-border {
  position: absolute;
  top: 50%;
  left: 20px;
  display: block;
  width: 30px;
  height: 3px;
  user-select: none;
  transition: background-color 0.3s;
  background: #fff;
}
@media screen and (max-width: 1440px) {
  .main-header__mobile-icon-border {
    width: 25px;
    left: 11px;
  }
}
@media screen and (max-width: 834px) {
  .main-header__mobile-icon-border {
    top: 47%;
    height: 2px;
    left: 12px;
    width: 17px;
  }
}
.main-header__mobile-icon-border:nth-child(1) {
  margin-top: 14px;
  margin-left: 0;
  width: 50px;
}
@media screen and (max-width: 1440px) {
  .main-header__mobile-icon-border:nth-child(1) {
    width: 40px;
    margin-top: 10px;
  }
}
@media screen and (max-width: 834px) {
  .main-header__mobile-icon-border:nth-child(1) {
    margin-top: 7px;
    width: 26px;
  }
}
.main-header__mobile-icon-border:nth-child(3) {
  margin-top: -14px;
  margin-left: 0;
  width: 50px;
}
@media screen and (max-width: 1440px) {
  .main-header__mobile-icon-border:nth-child(3) {
    width: 40px;
    margin-top: -10px;
  }
}
@media screen and (max-width: 834px) {
  .main-header__mobile-icon-border:nth-child(3) {
    width: 26px;
    margin-top: -7px;
  }
}

.main-header__mobile-icon-border:nth-child(1) {
  -webkit-animation: click-header-sp-bar-1-close 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards;
  animation: click-header-sp-bar-1-close 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards;
}

@-webkit-keyframes click-header-sp-bar-1-close {
  0% {
    transform: translateY(5px) rotate(45deg);
  }
  50% {
    transform: translateY(5px) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}
@keyframes click-header-sp-bar-1-close {
  0% {
    transform: translateY(5px) rotate(45deg);
  }
  50% {
    transform: translateY(5px) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}
.main-header__mobile-icon-border:nth-child(2) {
  -webkit-animation: click-header-sp-bar-2-close 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards;
  animation: click-header-sp-bar-2-close 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards;
}

@-webkit-keyframes click-header-sp-bar-2-close {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes click-header-sp-bar-2-close {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.main-header__mobile-icon-border:nth-child(3) {
  -webkit-animation: click-header-sp-bar-3-close 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards;
  animation: click-header-sp-bar-3-close 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards;
}

@-webkit-keyframes click-header-sp-bar-3-close {
  0% {
    transform: translateY(-5px) rotate(-45deg);
  }
  50% {
    transform: translateY(-5px) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}
@keyframes click-header-sp-bar-3-close {
  0% {
    transform: translateY(-5px) rotate(-45deg);
  }
  50% {
    transform: translateY(-5px) rotate(0);
  }
  100% {
    transform: translateY(0) rotate(0);
  }
}
.mobile-close .main-header__mobile-icon-border:nth-child(1) {
  margin-top: -5px;
  -webkit-animation: click-header-sp-bar-1-open 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards;
  animation: click-header-sp-bar-1-open 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards;
}

@-webkit-keyframes click-header-sp-bar-1-open {
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(5px) rotate(0);
  }
  100% {
    transform: translateY(5px) rotate(45deg);
  }
}
@keyframes click-header-sp-bar-1-open {
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(5px) rotate(0);
  }
  100% {
    transform: translateY(5px) rotate(45deg);
  }
}
.mobile-close .main-header__mobile-icon-border:nth-child(2) {
  -webkit-animation: click-header-sp-bar-2-open 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards;
  animation: click-header-sp-bar-2-open 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards;
}

@-webkit-keyframes click-header-sp-bar-2-open {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes click-header-sp-bar-2-open {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
.mobile-close .main-header__mobile-icon-border:nth-child(3) {
  margin-top: 5px;
  -webkit-animation: click-header-sp-bar-3-open 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards;
  animation: click-header-sp-bar-3-open 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards;
}

@-webkit-keyframes click-header-sp-bar-3-open {
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(-5px) rotate(0);
  }
  100% {
    transform: translateY(-5px) rotate(-45deg);
  }
}
@keyframes click-header-sp-bar-3-open {
  0% {
    transform: translateY(0) rotate(0);
  }
  50% {
    transform: translateY(-5px) rotate(0);
  }
  100% {
    transform: translateY(-5px) rotate(-45deg);
  }
}
.main-footer {
  position: relative;
  z-index: 2;
}
.main-footer__brand {
  padding: 81px 0;
  background: rgba(214, 225, 236, 0.5);
}
@media screen and (max-width: 834px) {
  .main-footer__brand {
    padding: 50px 0;
  }
}
.main-footer__brand-list {
  margin: 0 -15px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 31px 0;
}
@media screen and (max-width: 1245px) {
  .main-footer__brand-list {
    margin: 0 -10px;
    gap: 20px 0;
  }
}
@media screen and (max-width: 834px) {
  .main-footer__brand-list {
    gap: 14px 0;
    margin: 0 -7px;
  }
}
.main-footer__brand-item {
  padding: 0 15px;
  width: 33.33%;
}
@media screen and (max-width: 1245px) {
  .main-footer__brand-item {
    padding: 0 10px;
  }
}
@media screen and (max-width: 834px) {
  .main-footer__brand-item {
    width: 50%;
    padding: 0 7px;
  }
}
@media screen and (max-width: 640px) {
  .main-footer__brand-item {
    width: 100%;
  }
}
.main-footer__brand-item a {
  display: block;
}
@media screen and (max-width: 834px) {
  .main-footer__brand-item a {
    max-width: 400px;
    margin: 0 auto;
  }
}
.main-footer__brand-item a img {
  width: 100%;
}
.main-footer__content {
  padding: 116px 0 79px;
}
@media screen and (max-width: 834px) {
  .main-footer__content {
    padding: 60px 0;
  }
}
.main-footer__share {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 0 29px;
  margin-bottom: 50px;
  align-items: center;
}
@media screen and (max-width: 834px) {
  .main-footer__share {
    gap: 0 25px;
    margin-bottom: 35px;
  }
}
.main-footer__share-item a {
  display: block;
  color: #355381;
  font-size: 3.6rem;
}
@media screen and (max-width: 1245px) {
  .main-footer__share-item a {
    font-size: 3rem;
  }
}
@media screen and (max-width: 834px) {
  .main-footer__share-item a {
    font-size: 2.4rem;
  }
}
.main-footer__menu {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 20px 45px;
  margin-bottom: 60px;
}
@media screen and (max-width: 1245px) {
  .main-footer__menu {
    gap: 20px 30px;
  }
}
@media screen and (max-width: 834px) {
  .main-footer__menu {
    margin-bottom: 35px;
  }
}
.main-footer__menu-item a {
  display: block;
}
.main-footer__menu-item a img {
  width: auto;
  height: 14px;
}
@media screen and (max-width: 1245px) {
  .main-footer__menu-item a img {
    height: 13px;
  }
}
@media screen and (max-width: 834px) {
  .main-footer__menu-item a img {
    height: 12px;
  }
}
.main-footer__contact {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 20px 20px;
}
@media screen and (max-width: 834px) {
  .main-footer__contact {
    gap: 10px 20px;
  }
}
.main-footer__contact-item {
  max-width: 390px;
  width: 100%;
}
@media screen and (max-width: 1245px) {
  .main-footer__contact-item {
    max-width: 300px;
  }
}
@media screen and (max-width: 834px) {
  .main-footer__contact-item {
    max-width: 280px;
  }
}
.main-footer__contact-item a {
  display: block;
}
.main-footer__contact-item a img {
  width: 100%;
}
.main-footer__copyright {
  padding: 20px 10px;
  letter-spacing: 0.75px;
  text-align: center;
  color: #fff;
  background: #355381;
  font-weight: 500;
  font-size: 1.3rem;
}
@media screen and (max-width: 1245px) {
  .main-footer__copyright {
    letter-spacing: 0;
    padding: 15px 10px;
  }
}
@media screen and (max-width: 834px) {
  .main-footer__copyright {
    font-size: 1.2rem;
  }
}

section {
  position: relative;
}

.sec-marc, .sec-news, .sec-todays, .sec-journal {
  z-index: 2;
}

.mv-index {
  margin-bottom: -80px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
@media screen and (min-width: 1246px) {
  .mv-index {
    height: 140vh;
    height: 140dvh;
  }
}
@media screen and (max-width: 834px) {
  .mv-index {
    margin-bottom: -210px;
  }
}
.mv-index__left {
  width: calc(100% - 90px);
  overflow: hidden;
  position: relative;
  left: 0;
  top: 0;
  z-index: 2;
}
@media screen and (max-width: 1440px) {
  .mv-index__left {
    width: calc(100% - 60px);
  }
}
@media screen and (max-width: 834px) {
  .mv-index__left {
    width: 100%;
    margin-top: -5px;
  }
}
@media screen and (min-width: 835px) {
  .mv-index__slider {
    height: 100%;
  }
}
@media screen and (max-width: 1245px) {
  .mv-index__slider {
    min-height: 1119px;
  }
}
.mv-index__slider-list {
  width: max-content;
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  display: flex;
  overflow: hidden;
  gap: 0 50px;
  animation: infinity-scroll-left 80s infinite linear 0.5s both;
}
@media screen and (max-width: 834px) {
  .mv-index__slider-list {
    gap: 0 40px;
  }
}
@media screen and (min-width: 1246px) {
  .mv-index__slider-item {
    width: auto;
    height: 140vh;
    height: 140dvh;
  }
}
@media screen and (max-width: 1245px) {
  .mv-index__slider-item {
    width: 1200px;
  }
}
@media screen and (max-width: 834px) {
  .mv-index__slider-item {
    width: 650px;
  }
}
@media screen and (min-width: 1246px) {
  .mv-index__slider-item img {
    height: 100%;
    object-fit: cover;
  }
}
@media screen and (max-width: 834px) {
  .mv-index__slider-item img {
    width: 100%;
  }
}
.mv-index__ttl {
  width: 65%;
  position: absolute;
  top: 40.2%;
  transform: translateY(-50%);
  left: 89px;
  z-index: 20;
  max-width: 1007px;
}
@media screen and (max-width: 1440px) {
  .mv-index__ttl {
    width: 48%;
    top: 36.2%;
    left: 50px;
  }
}
@media screen and (max-width: 834px) {
  .mv-index__ttl {
    width: 83%;
    top: 35%;
    left: 40px;
    max-width: 500px;
  }
}
.mv-index__ttl img {
  width: 100%;
}
.mv-index__right {
  position: relative;
  width: 90px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  align-content: start;
  flex-wrap: wrap;
  z-index: 3;
}
@media screen and (min-width: 835px) {
  .mv-index__right {
    height: 100vh;
    height: 100dvh;
  }
}
@media screen and (max-width: 1440px) {
  .mv-index__right {
    width: 60px;
  }
}
@media screen and (max-width: 834px) {
  .mv-index__right {
    display: none;
  }
}
.mv-index__right-fixed {
  position: fixed;
  right: 0;
  width: 90px;
  top: 0;
  padding-top: 130px;
  z-index: 50;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  height: 80vh;
}
@media screen and (max-width: 1440px) {
  .mv-index__right-fixed {
    padding-top: 100px;
    width: 60px;
  }
}
@media screen and (max-width: 1245px) {
  .mv-index__right-fixed {
    width: 40px;
  }
}
.mv-index__share {
  width: 100%;
}
.mv-index__share-item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}
.mv-index__share-item:not(:last-child) {
  margin-bottom: 28px;
}
.mv-index__share-item a {
  writing-mode: tb-rl;
  display: block;
  color: #1f4073;
  font-size: 2.4rem;
}
.mv-index__copyright {
  width: 100%;
  writing-mode: tb-rl;
  color: #1f4073;
  font-weight: 700;
  display: flex;
  letter-spacing: 0.7px;
  align-items: center;
  margin-bottom: 30px;
  font-size: 1.3rem;
}
@media screen and (max-width: 1440px) {
  .mv-index__copyright {
    margin-bottom: 30px;
  }
}
.mv-index__scroll {
  display: flex;
  width: 100%;
  justify-content: center;
  position: absolute;
  bottom: 0;
}
@media screen and (max-width: 1024px) {
  .mv-index__scroll {
    display: none;
  }
}
.mv-index__scroll img {
  width: 19.5px;
}

.sec-marc {
  position: relative;
}
.sec-marc .inner {
  padding: 30px 0 424px;
  position: relative;
}
@media screen and (max-width: 834px) {
  .sec-marc .inner {
    padding: 30px 0 142px;
  }
}
@media screen and (max-width: 834px) {
  .sec-marc .inner {
    padding: 30px 0 288px;
  }
}

.marc-img {
  position: absolute;
}
.marc-img img {
  width: 100%;
}
.marc-img--01 {
  width: 262px;
  top: 21px;
  left: 11.8%;
}
@media screen and (max-width: 1440px) {
  .marc-img--01 {
    width: 220px;
    top: 50px;
  }
}
@media screen and (max-width: 834px) {
  .marc-img--01 {
    width: 35%;
    top: 46px;
    max-width: 170px;
    left: 8%;
  }
}
.marc-img--02 {
  width: 228px;
  right: -149px;
  top: 112px;
}
@media screen and (max-width: 1440px) {
  .marc-img--02 {
    width: 180px;
    right: 0;
    top: 42px;
  }
}
@media screen and (max-width: 834px) {
  .marc-img--02 {
    width: 30.2%;
    right: -5.5%;
    top: 91px;
    max-width: 170px;
  }
}
.marc-img--03 {
  width: 260px;
  left: -153px;
  bottom: 221px;
}
@media screen and (max-width: 1440px) {
  .marc-img--03 {
    width: 200px;
    left: -33px;
    bottom: 221px;
  }
}
@media screen and (max-width: 834px) {
  .marc-img--03 {
    width: 34%;
    left: -5.5%;
    bottom: 164px;
    max-width: 190px;
  }
}
.marc-img--04 {
  width: 374px;
  left: 195px;
  bottom: 71px;
}
@media screen and (max-width: 1440px) {
  .marc-img--04 {
    width: 300px;
    left: 225px;
  }
}
@media screen and (max-width: 834px) {
  .marc-img--04 {
    width: 50%;
    left: 38%;
    bottom: 41px;
    max-width: 250px;
  }
}

.marc-loop {
  width: max-content;
  position: absolute;
  top: 15%;
  left: 0;
  transform: translateY(-50%);
  white-space: nowrap;
  display: flex;
  overflow: hidden;
  gap: 0 50px;
  animation: infinity-scroll-left 80s infinite linear 0.5s both;
  opacity: 0.7;
}
@media screen and (max-width: 834px) {
  .marc-loop {
    top: -3%;
  }
}
@media screen and (max-width: 1440px) {
  .marc-loop__item {
    width: 2000px;
  }
}
@media screen and (max-width: 834px) {
  .marc-loop__item {
    width: 2400px;
  }
}
.marc-loop__item img {
  width: 100%;
}

.marc-flex {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  position: relative;
}
@media screen and (max-width: 834px) {
  .marc-flex {
    flex-wrap: wrap-reverse;
  }
}
.marc-flex__ct {
  width: 57.5%;
  padding-right: 95px;
  margin-top: -28px;
}
@media screen and (max-width: 1440px) {
  .marc-flex__ct {
    margin-top: 0;
    padding-right: 40px;
  }
}
@media screen and (max-width: 834px) {
  .marc-flex__ct {
    padding-right: 0;
    width: 100%;
  }
}
.marc-flex__ct .c-txt__01 {
  color: #1f4073;
  line-height: 2.22;
  margin-bottom: 65px;
  letter-spacing: -0.75px;
  font-weight: 700;
}
@media screen and (max-width: 1245px) {
  .marc-flex__ct .c-txt__01 {
    line-height: 2;
    letter-spacing: 0;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 834px) {
  .marc-flex__ct .c-txt__01 {
    margin-bottom: 43px;
    line-height: 1.964;
  }
}
.marc-flex__img {
  width: 42.5%;
}
@media screen and (max-width: 834px) {
  .marc-flex__img {
    width: 75.5%;
    margin: 0 auto 40px;
    max-width: 400px;
  }
}
.marc-flex__img img {
  width: 100%;
}

.sec-news {
  padding: 60px 0 80px;
}
@media screen and (max-width: 834px) {
  .sec-news {
    padding: 0 0 20px;
  }
}
.sec-news .inner {
  width: 90%;
  max-width: 1500px;
  border-radius: 10px;
  background: url("../img/index/bg-main.jpg") center no-repeat;
  padding: 82px 20px 111px;
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 1440px) {
  .sec-news .inner {
    padding: 82px 30px;
  }
}
@media screen and (max-width: 1245px) {
  .sec-news .inner {
    padding: 60px 25px;
  }
}
@media screen and (max-width: 834px) {
  .sec-news .inner {
    padding: 50px 20px;
  }
}
.sec-news .inner:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(190, 212, 235, 0.4);
  top: 0;
  left: 0;
  z-index: -1;
}

.news-list {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@media screen and (max-width: 834px) {
  .news-list {
    flex-wrap: wrap;
  }
}
.news-list__item {
  width: 50%;
  padding-right: 60px;
}
@media screen and (max-width: 1440px) {
  .news-list__item {
    padding-right: 30px;
  }
}
@media screen and (max-width: 834px) {
  .news-list__item {
    padding-right: 0;
    width: 100%;
  }
}
.news-list__item:nth-child(1) {
  border-right: 3px solid #1f4073;
}
@media screen and (max-width: 1245px) {
  .news-list__item:nth-child(1) {
    border-width: 2px;
  }
}
@media screen and (max-width: 834px) {
  .news-list__item:nth-child(1) {
    border-right: 0;
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid #1f4073;
  }
}
.news-list__item:nth-child(2) {
  padding-left: 60px;
  padding-right: 0;
}
@media screen and (max-width: 1440px) {
  .news-list__item:nth-child(2) {
    padding-left: 30px;
  }
}
@media screen and (max-width: 834px) {
  .news-list__item:nth-child(2) {
    padding-left: 0;
  }
}
.news-list__posts {
  margin-bottom: 40px;
}
@media screen and (max-width: 834px) {
  .news-list__posts {
    margin-bottom: 25px;
  }
}
.news-list__posts-item {
  border-bottom: 1px dotted #1f4073;
}
.news-list__posts-item:nth-child(1) {
  border-top: 1px dotted #1f4073;
}
.news-list__posts-item a {
  display: block;
  padding: 27px 20px 23px;
}
@media screen and (max-width: 1245px) {
  .news-list__posts-item a {
    padding: 20px 5px 16px;
  }
}
.news-list__posts-item time {
  line-height: 1.2;
  display: block;
  color: #1f4073;
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 1.4rem;
  font-feature-settings: "palt";
}
@media screen and (max-width: 1245px) {
  .news-list__posts-item time {
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 834px) {
  .news-list__posts-item time {
    font-size: 1.2rem;
  }
}
.news-list__posts-item p {
  color: #111;
}
.news-list__posts-inner {
  display: block;
  padding: 27px 20px 23px;
}
@media screen and (max-width: 1245px) {
  .news-list__posts-inner {
    padding: 20px 5px 16px;
  }
}
.news-list__schedule {
  margin-bottom: 99px;
}
@media screen and (max-width: 834px) {
  .news-list__schedule {
    margin-bottom: 25px;
  }
}
.news-list__schedule-item {
  border-bottom: 1px dotted #1f4073;
}
.news-list__schedule-item:nth-child(1) {
  border-top: 1px dotted #1f4073;
}
.news-list__schedule a, .news-list__schedule-inner {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 29px 20px 21px;
}
@media screen and (max-width: 1245px) {
  .news-list__schedule a, .news-list__schedule-inner {
    padding: 20px 5px 16px;
  }
}
.news-list__schedule time {
  width: 112px;
  line-height: 1.2;
  display: block;
  color: #1f4073;
  font-weight: 500;
  padding-top: 7px;
  font-size: 1.4rem;
  font-feature-settings: "palt";
}
@media screen and (max-width: 1245px) {
  .news-list__schedule time {
    width: 90px;
  }
}
@media screen and (max-width: 834px) {
  .news-list__schedule time {
    font-size: 1.2rem;
  }
}
.news-list__schedule-ct {
  width: calc(100% - 112px);
}
@media screen and (max-width: 1245px) {
  .news-list__schedule-ct {
    width: calc(100% - 90px);
  }
}
.news-list__schedule-ct span {
  background: #cc223a;
  display: inline-block;
  font-weight: 500;
  color: #fff;
  line-height: 1;
  margin-bottom: 9px;
  padding: 7px 12px 4px;
  font-size: 1.3rem;
}
@media screen and (max-width: 1245px) {
  .news-list__schedule-ct span {
    padding: 5px 10px 4px;
  }
}
@media screen and (max-width: 834px) {
  .news-list__schedule-ct span {
    font-size: 1.2rem;
  }
}
.news-list__schedule-ct p {
  color: #111;
  display: block;
  letter-spacing: 0.5px;
}
@media screen and (max-width: 1245px) {
  .news-list__schedule-ct p {
    letter-spacing: 0;
  }
}

.sec-todays {
  padding: 60px 0 80px;
}
@media screen and (max-width: 834px) {
  .sec-todays {
    padding: 30px 0;
  }
}
.sec-todays .c-ttl__01 {
  text-align: center;
  transform: translateX(-76px);
  margin-bottom: 60px;
}
@media screen and (max-width: 1245px) {
  .sec-todays .c-ttl__01 {
    margin-bottom: 40px;
    transform: translateX(0);
  }
}
@media screen and (max-width: 834px) {
  .sec-todays .c-ttl__01 {
    margin-bottom: 30px;
  }
}

.todays-box {
  padding: 73px 57px 60px 97px;
  border: 3px solid #1f4073;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.5);
}
@media screen and (max-width: 1245px) {
  .todays-box {
    padding: 50px 30px;
    border-width: 2px;
  }
}
@media screen and (max-width: 834px) {
  .todays-box {
    padding: 40px 20px;
    border-width: 1px;
  }
}
.todays-box__scroll {
  height: 449px;
  overflow-y: auto;
}
.todays-box__heading {
  text-align: center;
  font-weight: 600;
  margin-right: 40px;
  color: #333333;
  padding-bottom: 21px;
  border-bottom: 1px solid #1F4073;
  font-size: 3rem;
}
@media screen and (max-width: 1245px) {
  .todays-box__heading {
    padding-bottom: 10px;
    font-size: 2.4rem;
  }
}
@media screen and (max-width: 834px) {
  .todays-box__heading {
    margin-bottom: 10px;
    margin-right: 0;
    font-size: 2.2rem;
  }
}
.todays-box__date {
  display: block;
  text-align: right;
  margin-bottom: 36px;
  letter-spacing: 1px;
  color: #cc223a;
  margin-top: 12px;
  font-weight: bold;
  font-size: 1.6rem;
}
@media screen and (max-width: 1245px) {
  .todays-box__date {
    margin-bottom: 24px;
  }
}
@media screen and (max-width: 834px) {
  .todays-box__date {
    margin-top: 0;
    letter-spacing: 0;
    margin-bottom: 15px;
    font-size: 1.4rem;
  }
}
.todays-box__ct p {
  letter-spacing: 0.25px;
  line-height: 2;
}
@media screen and (max-width: 834px) {
  .todays-box__ct p {
    letter-spacing: 0;
    line-height: 1.6;
  }
}
.todays-box .mCSB_scrollTools {
  width: 4px !important;
}
@media screen and (max-width: 834px) {
  .todays-box .mCSB_scrollTools {
    width: 2px !important;
  }
}
.todays-box .mCSB_draggerRail {
  background: none;
}
.todays-box .mCSB_draggerContainer {
  width: 4px;
  background: #9ea9d6;
}
@media screen and (max-width: 834px) {
  .todays-box .mCSB_draggerContainer {
    width: 2px !important;
  }
}
.todays-box .mCSB_dragger_bar {
  width: 4px !important;
  border-radius: 0 !important;
  background: #1f4073 !important;
}
@media screen and (max-width: 834px) {
  .todays-box .mCSB_dragger_bar {
    width: 2px !important;
  }
}
.todays-box .mCSB_inside > .mCSB_container {
  margin-right: 38px;
}
@media screen and (max-width: 834px) {
  .todays-box .mCSB_inside > .mCSB_container {
    margin-right: 20px;
  }
}

.sec-journal {
  padding: 210px 0 150px;
  background: url(../img/index/bg-noise.jpg) 100% repeat;
}
@media screen and (max-width: 1245px) {
  .sec-journal {
    padding: 150px 0 100px;
  }
}
@media screen and (max-width: 834px) {
  .sec-journal {
    padding: 60px 0;
    padding: 50px 0 60px;
  }
}
.sec-journal:before {
  content: "";
  position: absolute;
  top: 50px;
  display: block;
  width: 100%;
  height: 100%;
  background: url("../img/index/img-map.png") top 0 left 80% no-repeat;
  background-size: 71%;
}
@media screen and (max-width: 1245px) {
  .sec-journal:before {
    background-size: 100%;
  }
}
@media screen and (max-width: 834px) {
  .sec-journal:before {
    background: url("../img/index/img-map.png") top 0 left 20% no-repeat;
    background-size: 120%;
    top: 10px;
  }
}
.sec-journal .c-ttl__01 {
  text-align: center;
  margin-bottom: 81px;
  transform: translateX(31px);
}
@media screen and (max-width: 1245px) {
  .sec-journal .c-ttl__01 {
    transform: translateX(0);
  }
}
@media screen and (max-width: 834px) {
  .sec-journal .c-ttl__01 {
    margin-bottom: 38px;
  }
}
.sec-journal .inner {
  max-width: 1500px;
}

.journal-flex {
  max-width: 890px;
  width: 100%;
  margin: 0 auto 76px;
}
@media screen and (max-width: 834px) {
  .journal-flex {
    max-width: 81%;
    margin: 0 auto 40px;
  }
}
.journal-flex .c-btn__02 {
  padding-right: 18px;
  margin-right: 4px;
}
.journal-flex a {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
@media screen and (max-width: 834px) {
  .journal-flex a {
    flex-wrap: wrap;
  }
}
.journal-flex__img {
  overflow: hidden;
  width: 351px;
  position: relative;
  border-radius: 10px;
  border: 2px solid #1F4073;
}
@media screen and (max-width: 1245px) {
  .journal-flex__img {
    width: 280px;
  }
}
@media screen and (max-width: 834px) {
  .journal-flex__img {
    width: 100%;
    margin-bottom: 15px;
  }
}
.journal-flex__img img {
  width: 100%;
}
.journal-flex__img-thumb {
  position: relative;
  overflow: hidden;
}
.journal-flex__img-thumb::before {
  content: "";
  float: left;
  padding-top: 71.50997151%;
}
.journal-flex__img-thumb::after {
  content: "";
  display: table;
  clear: both;
}
.journal-flex__img-thumb > :first-child {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  object-fit: cover;
}
.journal-flex__img-thumb > :first-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.journal-flex__new {
  display: inline-block;
  background: #cc223a;
  padding: 5px 10px;
  color: #fff;
  font-weight: 600;
  position: absolute;
  border-radius: 6px 0 10px 0;
  font-size: 2rem;
  z-index: 2;
}
@media screen and (max-width: 834px) {
  .journal-flex__new {
    padding: 3px 9px 2px 6px;
    font-size: 1.6rem;
  }
}
.journal-flex__ct {
  padding-left: 52px;
  margin-top: -1px;
  width: calc(100% - 351px);
}
@media screen and (max-width: 1245px) {
  .journal-flex__ct {
    padding-left: 30px;
    width: calc(100% - 280px);
  }
}
@media screen and (max-width: 834px) {
  .journal-flex__ct {
    padding-left: 0;
    width: 100%;
  }
  .journal-flex__ct .c-al--r {
    text-align: center !important;
  }
}
.journal-flex__ct .c-txt__01 {
  margin-bottom: 45px;
  color: #111;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  max-height: 108px;
}
@media screen and (max-width: 1245px) {
  .journal-flex__ct .c-txt__01 {
    max-height: 102px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 834px) {
  .journal-flex__ct .c-txt__01 {
    max-height: 88px;
    margin-bottom: 24px;
    -webkit-line-clamp: 4;
    line-height: 1.571;
  }
}
.journal-flex__date {
  color: #cc223a;
  font-weight: 600;
  display: block;
  margin-bottom: 11px;
  font-size: 2rem;
  font-feature-settings: "palt";
}
@media screen and (max-width: 1245px) {
  .journal-flex__date {
    margin-bottom: 6px;
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 834px) {
  .journal-flex__date {
    margin-bottom: -2px;
    font-size: 1.5rem;
  }
}
.journal-flex__ttl {
  margin-bottom: 13px;
  font-weight: 600;
  color: #1f4073;
  font-size: 2.4rem;
}
@media screen and (max-width: 1245px) {
  .journal-flex__ttl {
    margin-bottom: 10px;
    font-size: 2.2rem;
  }
}
@media screen and (max-width: 834px) {
  .journal-flex__ttl {
    margin-bottom: 7px;
    font-size: 1.7rem;
  }
}

.journal-menu {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 0 20px;
  border-bottom: 3px solid #1f4073;
}
@media screen and (max-width: 1245px) {
  .journal-menu {
    gap: 10px 10px;
  }
}
@media screen and (max-width: 834px) {
  .journal-menu {
    border-bottom: 0;
    gap: 10px 14px;
    padding: 0 8px 28px;
  }
}
@media screen and (max-width: 834px) {
  .journal-menu__item {
    width: calc(50% - 10px);
  }
}
.journal-menu__item a {
  text-align: center;
  display: block;
  padding: 10px 10px 5px;
  border-radius: 5px 5px 0 0;
  min-width: 200px;
  background: #1f4073;
  color: #fff;
  font-weight: bold;
  font-size: 1.8rem;
}
@media screen and (max-width: 1245px) {
  .journal-menu__item a {
    min-width: 150px;
  }
}
@media screen and (max-width: 834px) {
  .journal-menu__item a {
    padding: 12px 10px 8px;
    border-radius: 5px;
    font-size: 1.6rem;
  }
}
@media (any-hover: hover) {
  .journal-menu__item a:hover {
    opacity: 1;
    background: #cc223a;
    position: relative;
    transition: all 0.3s;
  }
  .journal-menu__item a:hover:before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 3px;
    background: #cc223a;
    bottom: -3px;
    left: 0;
    transition: all 0.3s;
  }
}
.journal-menu__item.active a {
  background: #cc223a;
  position: relative;
  transition: all 0.3s;
}
.journal-menu__item.active a:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 3px;
  background: #cc223a;
  bottom: -3px;
  left: 0;
  transition: all 0.3s;
}
@media screen and (max-width: 834px) {
  .journal-menu__item.active a:before {
    display: none;
  }
}

.journal-list {
  background: rgba(255, 255, 255, 0.6);
  padding: 60px 90px 71px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 73px 0;
  margin-bottom: 83px;
  border-radius: 0 0 15px 15px;
  position: relative;
  z-index: 3;
  display: none;
}
@media screen and (max-width: 1440px) {
  .journal-list {
    padding: 60px 50px 71px;
  }
}
@media screen and (max-width: 1245px) {
  .journal-list {
    padding: 60px 30px;
    margin-bottom: 50px;
  }
}
@media screen and (max-width: 834px) {
  .journal-list {
    margin: 0 -6%;
    padding: 39px 25px;
    background: none;
    width: 112%;
    gap: 48px 0;
    border-top: 3px solid #1f4073;
  }
}
.journal-list.active {
  display: flex;
}
.journal-list__item {
  margin-right: 66px;
  width: calc(33.33% - 44px);
}
@media screen and (max-width: 1245px) {
  .journal-list__item {
    margin-right: 30px;
    width: calc(33.33% - 20px);
  }
}
@media screen and (max-width: 834px) {
  .journal-list__item {
    margin-right: 30px;
    width: calc(50% - 15px);
  }
  .journal-list__item:nth-child(2n) {
    margin-right: 0;
  }
}
@media screen and (max-width: 640px) {
  .journal-list__item {
    width: 100%;
    margin-right: 0 !important;
  }
}
@media screen and (min-width: 835px) {
  .journal-list__item:nth-child(3n) {
    margin-right: 0;
  }
}
.journal-list__item a {
  display: block;
  color: #111111;
}
.journal-list__item p {
  color: #111;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
@media screen and (max-width: 834px) {
  .journal-list__item p {
    line-height: 1.73;
  }
}
.journal-list__img {
  max-width: 300px;
  margin: 0 auto 26px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  border: 3px solid #1f4073;
}
.journal-list__img::before {
  content: "";
  float: left;
  padding-top: 100%;
}
.journal-list__img::after {
  content: "";
  display: table;
  clear: both;
}
.journal-list__img > :first-child {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  object-fit: cover;
}
.journal-list__img > :first-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 834px) {
  .journal-list__img {
    margin-bottom: 28px;
    max-width: 275px;
  }
}
.journal-list__time {
  display: block;
  color: #cc223a;
  font-weight: 600;
  margin-bottom: 13px;
  font-size: 1.8rem;
  font-feature-settings: "palt";
}
@media screen and (max-width: 1245px) {
  .journal-list__time {
    margin-bottom: 10px;
    font-size: 1.7rem;
  }
}
@media screen and (max-width: 834px) {
  .journal-list__time {
    margin-bottom: 8px;
    font-size: 1.6rem;
  }
}
.journal-list__ttl {
  color: #111;
  font-weight: 600;
  margin-bottom: 17px;
  font-size: 2rem;
}
@media screen and (max-width: 1245px) {
  .journal-list__ttl {
    margin-bottom: 10px;
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 834px) {
  .journal-list__ttl {
    margin-bottom: 16px;
    font-size: 1.6rem;
  }
}
.journal-list__fav {
  text-align: right;
}
.journal-list__fav .wpulike {
  margin: 0 !important;
}
.journal-list__fav .wp_ulike_general_class {
  border: none !important;
  padding: 0 !important;
  box-shadow: none !important;
}
.journal-list__fav .wp_ulike_general_class button {
  color: #1f4073;
  font-weight: 600;
  font-size: 1.4rem;
}
.journal-list__fav .wp_ulike_general_class button::after {
  background: url(../img/common/heart-off.png) no-repeat 0 0/100% auto !important;
  width: 20px !important;
  height: 20px !important;
}
.journal-list__fav .wp_ulike_general_class button.wp_ulike_btn_is_active::after {
  background-image: url(../img/common/heart.png) !important;
  filter: none !important;
}
.journal-list__fav .wp_ulike_general_class .count-box {
  color: #08466d !important;
}

@keyframes infinity-scroll-left {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}
.news-page {
  padding-top: clamp(10em, 20vw, 26.5em);
  padding-bottom: clamp(5em, 10vw, 12em);
  position: relative;
  z-index: 2;
}
.news-page__ttl {
  max-width: 540px;
  margin: 0 auto clamp(3em, 10vw, 13.7em);
}
@media screen and (max-width: 834px) {
  .news-page__ttl {
    max-width: 300px;
  }
}

.tabs-panel {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 900px;
  margin: 0 auto;
}
@media screen and (max-width: 1024px) {
  .tabs-panel {
    gap: 0 10px;
    width: 95%;
  }
}
.tabs-panel li {
  max-width: 400px;
  width: 50%;
}
.tabs-panel li a, .tabs-panel li span {
  width: 100%;
  height: 55px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: "IBM Plex Sans JP", sans-serif;
  font-size: 2.4rem;
  font-weight: 700;
  color: #1f4073;
  border: 3px solid #557393;
  border-bottom: none;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
}
@media screen and (max-width: 834px) {
  .tabs-panel li a, .tabs-panel li span {
    width: 100%;
    font-size: 1.8rem;
    height: 40px;
    border-width: 2px;
  }
}
.tabs-panel li.active a, .tabs-panel li.active span {
  color: #fff;
  background: #1f4073;
  border-color: #1f4073;
}

.tab-contents {
  display: none;
  border-top: 3px solid #1f4073;
  padding: clamp(0.5em, 1vw, 1em) clamp(2.5em, 7vw, 9em);
  background: rgba(255, 255, 255, 0.6);
}
@media screen and (max-width: 834px) {
  .tab-contents {
    border-width: 2px;
  }
}
.tab-contents.active {
  display: block;
}

.news-block {
  margin-bottom: clamp(3em, 7vw, 9em);
}
.news-block__item {
  padding: 60px 0;
  border-bottom: 1px dotted #1f4073;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.news-block__item:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 834px) {
  .news-block__item {
    padding: 30px 0;
  }
}
.news-block__item:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 1px;
  background: url(../img/news/line.png) repeat-x center/2px 1px;
  bottom: 0;
  left: 0;
}
.news-block__img {
  width: 380px;
  border: 3px solid #1f4073;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
@media screen and (max-width: 1024px) {
  .news-block__img {
    width: 280px;
  }
}
@media screen and (max-width: 834px) {
  .news-block__img {
    width: 250px;
  }
}
@media screen and (max-width: 640px) {
  .news-block__img {
    width: 100%;
  }
}
.news-block__img:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 26px;
  height: 26px;
  background: url(../img/news/icon-arrow.png) no-repeat center/100%;
  bottom: 10px;
  right: 10px;
}
.news-block__img-thumb {
  position: relative;
  overflow: hidden;
}
.news-block__img-thumb::before {
  content: "";
  float: left;
  padding-top: 73.6842105263%;
}
.news-block__img-thumb::after {
  content: "";
  display: table;
  clear: both;
}
.news-block__img-thumb > :first-child {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  object-fit: cover;
}
.news-block__img-thumb > :first-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.news-block__title {
  font-size: 2.2rem;
  line-height: 1.8181818182;
  font-weight: 500;
  margin-bottom: 25px;
}
@media screen and (max-width: 834px) {
  .news-block__title {
    font-size: 1.6rem;
    margin-bottom: 15px;
  }
}
.news-block__ct {
  width: calc(100% - 380px);
  padding-left: clamp(3em, 5vw, 6em);
}
@media screen and (max-width: 1024px) {
  .news-block__ct {
    width: calc(100% - 280px);
  }
}
@media screen and (max-width: 834px) {
  .news-block__ct {
    width: calc(100% - 250px);
  }
}
@media screen and (max-width: 640px) {
  .news-block__ct {
    width: 100%;
    padding-left: 0;
    padding-top: 15px;
  }
}
.news-block__ct time {
  font-size: 2rem;
  font-weight: 600;
  color: #1f4073;
  display: block;
  margin-bottom: 23px;
}
@media screen and (max-width: 834px) {
  .news-block__ct time {
    font-size: 1.6rem;
    margin-bottom: 10px;
  }
}
.news-block__ct .c-btn__02 {
  padding-right: 19px;
}
.news-block__ct .c-btn__02::before, .news-block__ct .c-btn__02:after {
  height: 1px;
  background: #000000;
}
.news-block__ct .c-btn__02 img {
  height: 12px;
}

.wp-pagenavi {
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.wp-pagenavi .page, .wp-pagenavi .current {
  display: none;
}
.wp-pagenavi .pages {
  order: 2;
  font-family: "IBM Plex Sans JP", sans-serif;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 1px;
  color: #1f4073;
  padding: 0 40px;
}
@media screen and (max-width: 834px) {
  .wp-pagenavi .pages {
    font-size: 2.4rem;
    padding: 0 30px;
  }
}
.wp-pagenavi .pages span {
  font-size: 2rem;
  margin-left: 2px;
}
@media screen and (max-width: 834px) {
  .wp-pagenavi .pages span {
    font-size: 1.6rem;
  }
}
.wp-pagenavi .previouspostslink, .wp-pagenavi .nextpostslink {
  width: 100px;
  position: relative;
  padding-bottom: 10px;
}
@media screen and (max-width: 834px) {
  .wp-pagenavi .previouspostslink, .wp-pagenavi .nextpostslink {
    width: 80px;
    padding-bottom: 5px;
  }
}
.wp-pagenavi .previouspostslink img, .wp-pagenavi .nextpostslink img {
  height: 11px;
}
.wp-pagenavi .previouspostslink:after, .wp-pagenavi .nextpostslink:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 10px;
  height: 2px;
  background: #1F4073;
  right: -2px;
  transform: rotate(48deg);
  bottom: 5px;
  width: 13px;
  transition: all 0.3s;
}
.wp-pagenavi .previouspostslink:before, .wp-pagenavi .nextpostslink:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 2px;
  background: #1F4073;
  right: 0;
  bottom: 0;
  left: 0;
  transform: scale(1);
  transition: all 0.3s;
  transform-origin: right;
}
.wp-pagenavi .nextpostslink {
  position: absolute;
  top: 0;
  right: 50%;
  margin-right: -180px;
  order: 3;
  text-align: left;
}
@media screen and (max-width: 834px) {
  .wp-pagenavi .nextpostslink {
    margin-right: -140px;
  }
}
@media (any-hover: hover) {
  .wp-pagenavi .nextpostslink:hover {
    opacity: 1;
  }
  .wp-pagenavi .nextpostslink:hover::before {
    width: calc(100% + 10px);
    transition: all 0.3s;
  }
  .wp-pagenavi .nextpostslink:hover::after {
    right: -13px;
    transition: all 0.3s;
  }
}
.wp-pagenavi .previouspostslink {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -180px;
  text-align: right;
  order: 1;
}
@media screen and (max-width: 834px) {
  .wp-pagenavi .previouspostslink {
    margin-left: -140px;
  }
}
.wp-pagenavi .previouspostslink:after {
  right: auto;
  left: -2px;
  transform: rotate(-48deg);
}
@media (any-hover: hover) {
  .wp-pagenavi .previouspostslink:hover {
    opacity: 1;
  }
  .wp-pagenavi .previouspostslink:hover::before {
    transform: scale(1.1);
  }
  .wp-pagenavi .previouspostslink:hover::after {
    left: -12px;
    transition: all 0.3s;
  }
}

.schedule-block {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 60px 0;
}
@media screen and (max-width: 834px) {
  .schedule-block {
    padding: 30px 0;
  }
}
.schedule-block__sidebar {
  width: 250px;
}
@media screen and (max-width: 834px) {
  .schedule-block__sidebar {
    width: 100%;
  }
}
@media screen and (max-width: 834px) {
  .schedule-block__sidebar-list {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    margin-bottom: 30px;
  }
}
.schedule-block__sidebar-list li {
  margin-bottom: 30px;
}
@media screen and (max-width: 834px) {
  .schedule-block__sidebar-list li {
    margin-bottom: 0;
  }
}
.schedule-block__sidebar-list li:last-of-type {
  margin-bottom: 0;
}
.schedule-block__sidebar-list li a {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 50px;
  color: #1f4073;
  background: #fff;
  border: 2px solid #1f4073;
  border-radius: 4px;
  font-size: 2rem;
  font-weight: 700;
}
@media screen and (max-width: 834px) {
  .schedule-block__sidebar-list li a {
    height: 40px;
    font-size: 1.7rem;
  }
}
.schedule-block__sidebar-list li.is-active a {
  background: #1f4073;
  color: #fff;
}
.schedule-block__content {
  width: calc(100% - 250px);
  padding-left: clamp(3em, 7vw, 9em);
}
@media screen and (max-width: 834px) {
  .schedule-block__content {
    padding-left: 0;
    width: 100%;
  }
}
.schedule-block__content .c-btn__02::before {
  position: relative;
}
.schedule-block__btn {
  position: relative;
  display: inline-flex;
  align-items: flex-end;
  gap: 0 10px;
}
.schedule-block__btn:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 50px;
  height: 2px;
  background: #1F4073;
  position: relative;
  transition: all 0.3s;
  transform: scale(1);
  transform-origin: left;
}
.schedule-block__btn:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 10px;
  height: 2px;
  background: #1F4073;
  right: -2px;
  transform: rotate(48deg);
  bottom: 5px;
  width: 13px;
  transition: all 0.3s;
}
.schedule-block__btn img {
  height: 13px;
}
.schedule-block__btn:hover:after {
  transform: scale(1.2);
}
.schedule-block__btn:hover::before {
  right: -13px;
}
.schedule-block__list {
  margin-bottom: 50px;
}
@media screen and (max-width: 834px) {
  .schedule-block__list {
    margin-bottom: 30px;
  }
}
.schedule-block__list-item {
  position: relative;
}
.schedule-block__list-item::before, .schedule-block__list-item:after {
  content: "";
  display: block;
  width: 100%;
  height: 1px;
  background: url(../img/news/line.png) repeat-x center/2px 1px;
  left: 0;
}
.schedule-block__list-item::before {
  display: none;
}
.schedule-block__list-item::after {
  bottom: 0;
}
.schedule-block__list-item:nth-child(1)::before {
  display: block;
}
.schedule-block__list-item:nth-child(even) .schedule-block__list-link {
  background: #f2f4f7;
}
.schedule-block__list-link {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 28px 40px;
  background: #fff;
}
@media screen and (max-width: 1245px) {
  .schedule-block__list-link {
    flex-wrap: wrap;
    padding: 15px;
  }
}
.schedule-block__list-cate {
  width: 80px;
  font-size: 1.3rem;
  color: #fff;
  background: #cc223a;
  font-weight: 500;
  display: inline-block;
  padding: 7px 0 5px;
  text-align: center;
  margin-right: 20px;
  line-height: 1;
}
.schedule-block__list-time {
  font-size: 1.4rem;
  font-weight: 500;
  color: #1f4073;
}
.schedule-block__list-ttl {
  font-size: 1.6rem;
  color: #111;
  padding-left: 30px;
}
@media screen and (max-width: 1245px) {
  .schedule-block__list-ttl {
    padding-left: 0;
    padding-top: 15px;
    width: 100%;
  }
}

#zoomback {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  display: none;
}

#zoomimg {
  width: 90%;
  min-width: 300px;
  max-width: 1000px;
}
#zoomimg img {
  width: 100%;
}

.deka {
  animation: deka 0.3s ease-out;
}

@keyframes deka {
  from {
    transform: scale(0.9);
  }
}
.zoomimg {
  display: none;
  width: 100%;
}

.blog-page {
  position: relative;
  z-index: 2;
  padding: clamp(10em, 20vw, 25em) 0 120px;
  background: url(../img/blog/before-01.png) no-repeat 81% 3%/40.95% auto;
}
@media screen and (max-width: 834px) {
  .blog-page {
    padding-bottom: 50px;
    background: url(../img/blog/before-01.png) no-repeat 81% 0/90% auto;
  }
}
.blog-page__ttl {
  max-width: 564px;
  margin: 0 auto clamp(3em, 10vw, 12em);
}
@media screen and (max-width: 834px) {
  .blog-page__ttl {
    max-width: 300px;
  }
}
.blog-page .blog-tabs {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background: rgba(255, 255, 255, 0.6);
  padding: clamp(1.5em, 4.5vw, 7em) clamp(2.5em, 7vw, 9em);
}
@media screen and (max-width: 834px) {
  .blog-page .blog-tabs {
    border-top: 3px solid #1f4073;
  }
}
.blog-page .blog-tabs__content {
  width: calc(100% - 270px);
  display: none;
  padding-top: 1em;
}
@media screen and (max-width: 1024px) {
  .blog-page .blog-tabs__content {
    order: 1;
    width: 100%;
  }
}
.blog-page .blog-tabs__content.active {
  display: block;
}
.blog-page .blog-tabs__archive {
  width: 270px;
  position: sticky;
  top: 0;
  padding-top: 1em;
}
@media screen and (max-width: 1024px) {
  .blog-page .blog-tabs__archive {
    width: 100%;
    order: 3;
  }
}
.blog-page .blog-tabs__archive-ttl {
  width: 168px;
  margin-bottom: 20px;
}
.blog-page .blog-tabs__archive-list {
  padding: 37px 20px;
  border-top: 1px dashed #1f4073;
}
@media screen and (max-width: 834px) {
  .blog-page .blog-tabs__archive-list {
    padding: 20px 15px 0;
  }
}
.blog-page .blog-tabs__archive-list li {
  margin-bottom: 12.5px;
}
.blog-page .blog-tabs__archive-list li:last-of-type {
  margin-bottom: 0;
}
.blog-page .blog-tabs__archive-list li a {
  font-size: 1.8rem;
  color: #1f4073;
  display: inline-flex;
  align-items: center;
  letter-spacing: 1px;
}
.blog-page .blog-tabs__archive-list li a span {
  font-size: 1rem;
  margin-right: 5px;
  position: relative;
  top: -2px;
}
.blog-page .blog-tabs__archive-list li a::before {
  content: "●";
  display: inline-block;
  font-size: 1rem;
  margin-right: 5px;
  position: relative;
  top: -2px;
}
.blog-page .blog-tabs .nums-page {
  width: 100%;
}
@media screen and (max-width: 1024px) {
  .blog-page .blog-tabs .nums-page {
    order: 2;
    margin-bottom: 50px;
  }
}
.blog-page .journal-list--styles02 {
  display: grid !important;
  grid-template-columns: 1fr 1fr;
  gap: 68px 75px;
  background: none;
  background: none;
  padding: 0;
  padding-right: clamp(3em, 7vw, 80px);
  margin-bottom: 66px;
  border: none;
}
@media screen and (max-width: 1245px) {
  .blog-page .journal-list--styles02 {
    gap: 40px 40px;
  }
}
@media screen and (max-width: 1024px) {
  .blog-page .journal-list--styles02 {
    padding-right: 0;
  }
}
@media screen and (max-width: 834px) {
  .blog-page .journal-list--styles02 {
    gap: 30px;
    margin: 0 0 30px;
    width: 100%;
  }
}
@media screen and (max-width: 640px) {
  .blog-page .journal-list--styles02 {
    grid-template-columns: 1fr;
  }
}
.blog-page .journal-list__item {
  margin-right: 0;
  width: 100%;
}
.blog-page .journal-list__img {
  border-radius: 10px;
  overflow: hidden;
  border: 3px solid #1f4073;
  margin-bottom: 30px;
}
@media screen and (max-width: 834px) {
  .blog-page .journal-list__img {
    margin-bottom: 15px;
  }
}

.box-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  overflow-y: auto;
  opacity: 0;
  visibility: hidden;
  z-index: 999;
  transition: 0.3s all;
}
.box-modal.is-active {
  opacity: 1;
  visibility: visible;
}
.box-modal__close {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(31, 64, 115, 0.7);
}
.box-modal__wp {
  max-width: 1000px;
  border-radius: 20px;
  border: 3px solid #1f4073;
  padding: clamp(2.5em, 6vw, 8em) clamp(2.5em, 8vw, 10em);
  margin: 50px auto;
  position: relative;
  z-index: 2;
  background: #fff;
}
@media screen and (max-width: 834px) {
  .box-modal__wp {
    width: 90%;
    margin: 30px 0;
  }
}
.box-modal__img {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  border: 3px solid #1f4073;
  margin-bottom: 42px;
}
.box-modal__img::before {
  content: "";
  float: left;
  padding-top: 64.75%;
}
.box-modal__img::after {
  content: "";
  display: table;
  clear: both;
}
.box-modal__img > :first-child {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  object-fit: cover;
}
.box-modal__img > :first-child img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width: 834px) {
  .box-modal__img {
    margin-bottom: 15px;
  }
}
.box-modal__img img {
  width: 100%;
}
.box-modal__time {
  display: block;
  color: #cc223a;
  font-weight: 600;
  margin-bottom: 13px;
  font-size: 1.8rem;
  font-feature-settings: "palt";
}
@media screen and (max-width: 1245px) {
  .box-modal__time {
    margin-bottom: 10px;
    font-size: 1.7rem;
  }
}
@media screen and (max-width: 834px) {
  .box-modal__time {
    margin-bottom: 8px;
    font-size: 1.6rem;
  }
}
.box-modal__ttl {
  color: #111;
  font-weight: 600;
  margin-bottom: 17px;
  font-size: 2rem;
}
@media screen and (max-width: 1245px) {
  .box-modal__ttl {
    margin-bottom: 10px;
    font-size: 1.8rem;
  }
}
@media screen and (max-width: 834px) {
  .box-modal__ttl {
    margin-bottom: 16px;
    font-size: 1.6rem;
  }
}
.box-modal__ct {
  padding: 0 10px;
}
.box-modal__btn {
  width: 50px;
  height: 45px;
  display: block;
  margin: 50px auto 0;
  background: url(../img/blog/btn-close.png) no-repeat center/100%;
  border: none;
  outline: 0;
  cursor: pointer;
}
@media screen and (max-width: 834px) {
  .box-modal__btn {
    margin-top: 30px;
  }
}
.box-modal__row {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-top: 2px solid #08466d;
  padding-top: 20px;
  margin-bottom: 60px;
  margin-top: 50px;
}
@media screen and (max-width: 834px) {
  .box-modal__row {
    margin-top: 30px;
    margin-bottom: 45px;
  }
}
.box-modal__btns {
  width: 310px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media screen and (max-width: 834px) {
  .box-modal__btns {
    width: 100%;
  }
}
.box-modal__btns-item:first-of-type {
  margin-right: 10px;
}
.box-modal__btns-item a, .box-modal__btns-item .wp_ulike_general_class {
  font-size: 1.4rem;
  font-weight: 600;
  box-shadow: none !important;
  padding: 0 !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 45px;
  text-align: center;
  width: 150px !important;
  background: #dae3e9 !important;
  border: none !important;
  color: #1f4073 !important;
  border-radius: 5px !important;
}
.box-modal__btns-item a {
  position: relative;
  padding-left: 20px !important;
}
.box-modal__btns-item a:hover {
  opacity: 1;
}
.box-modal__btns-item a::before {
  content: "";
  display: block;
  background: url("../img/common/comment.png") no-repeat 0 0/100% auto;
  width: 20px;
  height: 18px;
  position: absolute;
  top: 50%;
  left: 15px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.box-modal__btns-item .wp_ulike_general_class {
  position: relative;
}
.box-modal__btns-item .wp_ulike_general_class button {
  position: absolute !important;
  width: 100%;
  height: 100%;
  left: 0;
  padding: 0 !important;
}
.box-modal__btns-item .wp_ulike_general_class button::before {
  content: "いいね";
  color: #1f4073 !important;
}
.box-modal__btns-item .wp_ulike_general_class button::after {
  left: 25px !important;
  background: url(../img/common/heart-wh.png) no-repeat 0 0/100% auto !important;
  width: 20px !important;
  height: 19px !important;
}
.box-modal__btns-item .wp_ulike_general_class button.wp_ulike_btn_is_active::after {
  background-image: url(../img/common/heart.png) !important;
  filter: none !important;
}
.box-modal__btns-item .wp_ulike_general_class .count-box {
  position: absolute !important;
  right: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #1f4073 !important;
}
.box-modal__sns {
  width: calc(100% - 310px);
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media screen and (max-width: 834px) {
  .box-modal__sns {
    width: 100%;
    justify-content: flex-start;
    margin-top: 15px;
  }
}
.box-modal__sns-ttl {
  font-size: 1.6rem;
  font-weight: 600;
  color: #08466d;
  border-right: 1px solid #08466d;
  padding-right: 20px;
}
@media screen and (max-width: 834px) {
  .box-modal__sns-ttl {
    font-size: 1.4rem;
  }
}
.box-modal__sns-item {
  margin-left: 20px;
}

.comments .comment-form-comment label {
  display: none;
}
.comments__ttl,
.comments .comment-reply-title {
  position: relative;
  font-size: 1.8rem;
  font-weight: 600;
  padding-bottom: 12px;
  padding-left: 45px;
}
@media screen and (max-width: 834px) {
  .comments__ttl,
  .comments .comment-reply-title {
    font-size: 1.6rem;
  }
}
.comments__ttl::before,
.comments .comment-reply-title::before {
  content: "";
  display: block;
  background: url("../img/common/comment.png") no-repeat 0 0/100% auto;
  width: 24px;
  height: 21px;
  position: absolute;
  top: 0;
  left: 0;
}
.comments__ttl::after,
.comments .comment-reply-title::after {
  content: "";
  background: #08466d;
  opacity: 0.5;
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  bottom: 0;
}
.comments__ttl-mb,
.comments .comment-reply-title-mb {
  margin-bottom: 30px;
}
.comments .comment-reply-title {
  margin-bottom: 30px;
}
.comments #respond .comment-reply-title {
  display: none;
}
.comments #commentform p {
  color: #08466d;
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 10px;
}
@media screen and (max-width: 834px) {
  .comments #commentform p {
    font-size: 1.4rem;
  }
}
.comments #commentform .comment-form-comment,
.comments #commentform .comment-form-author {
  margin-bottom: 40px;
}
.comments #commentform .comment-form-author label {
  display: block;
  margin-bottom: 10px;
}
.comments #commentform textarea, .comments #commentform input[type=text] {
  display: block;
  width: 100%;
  height: 70px;
  background: white;
  font-weight: 500;
  padding: 10px 15px;
  font-size: 1.6rem;
  border-radius: 5px;
  border: 1px solid #bbc5d5;
}
@media screen and (max-width: 834px) {
  .comments #commentform textarea, .comments #commentform input[type=text] {
    font-size: 1.4rem;
    height: 50px;
  }
}
.comments #commentform textarea {
  padding: 20px;
  height: 300px;
  resize: vertical;
}
@media screen and (max-width: 834px) {
  .comments #commentform textarea {
    height: 200px;
  }
}
.comments #commentform .comment-form-cookies-consent {
  display: none;
}
.comments #commentform .form-submit input {
  cursor: pointer;
  display: block;
  width: 300px;
  height: 80px;
  font-size: 1.8rem;
  color: #FFF;
  text-align: left;
  padding: 0 0 0 30px;
  background: rgb(37, 81, 133);
  background: url("../img/common/arrow.png") no-repeat right 30px center/50px auto, linear-gradient(180deg, rgb(37, 81, 133) 35%, rgb(52, 119, 172) 100%);
  border: none;
  margin: 0 auto;
}
@media screen and (max-width: 834px) {
  .comments #commentform .form-submit input {
    font-size: 1.4rem;
    height: 65px;
    max-width: 100%;
  }
}
.comments #commentform .logged-in-as {
  display: none;
}
.comments__list {
  margin-bottom: 60px;
}
.comments__list .comment-body {
  position: relative;
}
.comments__list .comment-author {
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 600;
  margin-right: 25px;
  padding-right: 25px;
  border-right: #a6a6a6 2px solid;
  line-height: 1;
}
@media screen and (max-width: 834px) {
  .comments__list .comment-author {
    padding-right: 10px;
    margin-right: 10px;
  }
}
.comments__list .comment-author .says {
  display: none;
}
.comments__list .comment-author .fn {
  font-size: 1.6rem;
  font-weight: 600;
}
@media screen and (max-width: 834px) {
  .comments__list .comment-author .fn {
    font-size: 1.2rem;
  }
}
.comments__list .comment-author .fn a {
  color: #111;
  pointer-events: none;
}
.comments__list .comment-meta {
  display: inline-block;
  line-height: 1;
}
.comments__list .comment-meta a {
  color: #111;
  pointer-events: none;
  font-size: 1.6rem;
  font-weight: 600;
}
@media screen and (max-width: 834px) {
  .comments__list .comment-meta a {
    font-size: 1.2rem;
  }
}
.comments__list > li {
  position: relative;
  padding: 30px 0 60px;
  border-bottom: 1px solid #dae3e9;
}
.comments__list > li:last-of-type {
  margin-bottom: 0;
  border-bottom: none;
}
.comments__list > li p {
  margin-top: 15px;
  font-size: 1.6rem;
  line-height: 2;
}
@media screen and (max-width: 834px) {
  .comments__list > li p {
    font-size: 1.3rem;
    line-height: 1.6;
  }
}
.comments__list .children {
  margin-top: 45px;
  margin-left: 25px;
  padding-left: 20px;
  border-left: 2px solid #6b90a7;
}
@media screen and (max-width: 834px) {
  .comments__list .children {
    margin-left: 10px;
    padding-left: 10px;
  }
}
.comments__list .children li {
  position: relative;
  padding-bottom: 60px;
}
.comments__list .wpulike {
  position: absolute;
  right: 80px;
  bottom: -40px;
  display: inline-block !important;
  padding: 0 !important;
  vertical-align: middle;
}
.comments__list .wp_ulike_general_class {
  border: none !important;
  padding: 0 !important;
  box-shadow: none !important;
}
.comments__list .wp_ulike_general_class button {
  color: #1f4073;
  font-weight: 600;
  font-size: 1.4rem;
}
.comments__list .wp_ulike_general_class button::after {
  background: url(../img/common/heart-off.png) no-repeat 0 0/100% auto !important;
  width: 15px !important;
  height: 13px !important;
}
.comments__list .wp_ulike_general_class button.wp_ulike_btn_is_active::after {
  background-image: url(../img/common/heart.png) !important;
  filter: none !important;
}
.comments__list .reply {
  position: absolute;
  right: 0;
  bottom: -30px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 25px;
}
.comments__list .reply a {
  position: relative;
  padding-left: 25px;
  color: #1f4073;
  font-weight: 600;
  font-size: 1.4rem;
}
.comments__list .reply a::before {
  content: "";
  display: block;
  background: url("../img/common/res.png") no-repeat 0 0/100% auto;
  width: 15px;
  height: 11px;
  position: absolute;
  top: 50%;
  margin-top: -5px;
  left: 0;
}

.contact-page {
  position: relative;
  z-index: 2;
  padding: clamp(10em, 20vw, 26.5em) 0 clamp(5em, 9vw, 15em);
}
.contact-page__ttl {
  max-width: 305px;
  margin: 0 auto clamp(5em, 9vw, 13em);
}
@media screen and (max-width: 834px) {
  .contact-page__ttl {
    max-width: 220px;
  }
}
.contact-page__intro {
  text-align: center;
  margin-bottom: 67px;
  font-weight: 700;
}
@media screen and (max-width: 834px) {
  .contact-page__intro {
    margin-bottom: 30px;
    text-align: left;
  }
}
.contact-page__form {
  font-size: 1.8rem;
  line-height: 2;
  margin-bottom: clamp(5em, 7vw, 10em);
}
@media screen and (max-width: 1245px) {
  .contact-page__form {
    font-size: 1.7rem;
  }
}
@media screen and (max-width: 834px) {
  .contact-page__form {
    font-size: 1.4rem;
  }
}
.contact-page__dt {
  width: 340px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 17px;
  font-weight: bold;
}
@media screen and (max-width: 1024px) {
  .contact-page__dt {
    width: 280px;
  }
}
@media screen and (max-width: 834px) {
  .contact-page__dt {
    width: 100%;
    padding-top: 0;
    margin-bottom: 10px;
  }
}
.contact-page__dt p {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.contact-page__dd {
  width: calc(100% - 340px);
}
@media screen and (max-width: 1024px) {
  .contact-page__dd {
    width: calc(100% - 280px);
  }
}
@media screen and (max-width: 834px) {
  .contact-page__dd {
    width: 100%;
  }
}
.contact-page__dd--radio {
  padding-top: 20px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 13px 40px;
}
@media screen and (max-width: 834px) {
  .contact-page__dd--radio {
    padding-top: 10px;
  }
}
.contact-page__dd.has-error {
  margin-bottom: 22px;
  position: relative;
}
.contact-page__dd.has-error .form-control {
  background: #f9ebeb;
}
.contact-page__dd.has-error:after {
  content: "必須項目です";
  font-size: 1.4rem;
  font-weight: 500;
  color: #cc223a;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
}
@media screen and (max-width: 834px) {
  .contact-page__dd.has-error:after {
    font-size: 1.3rem;
  }
}
.contact-page__dd.has-error .radio .checkmark {
  background-color: #f9ebeb;
}
.contact-page__dl {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 40px;
}
@media screen and (max-width: 834px) {
  .contact-page__dl {
    margin-bottom: 20px;
  }
}
.contact-page__dl:nth-last-child(2) {
  margin-bottom: clamp(3em, 7vw, 10em);
}
@media screen and (min-width: 835px) {
  .contact-page__dl--form .contact-page__dt {
    padding-top: 45px;
  }
}
.contact-page__btn {
  background: rgb(32, 68, 119);
  background: linear-gradient(180deg, rgb(32, 68, 119) 0%, rgb(45, 102, 156) 100%);
  max-width: 350px;
  height: 80px;
  width: 100%;
  margin: 0 auto;
  cursor: pointer;
  outline: 0;
  border: none;
  color: #fff;
  font-family: "IBM Plex Sans JP", sans-serif;
  font-size: 2.2rem;
  font-weight: 500;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media screen and (max-width: 834px) {
  .contact-page__btn {
    font-size: 2rem;
    max-width: 300px;
    height: 60px;
  }
}
.contact-page__btn:hover {
  opacity: 0.7;
}
.contact-page__info {
  max-width: 845px;
  width: 100%;
  margin: 0 auto;
  border-radius: 5px;
  border: 3px solid #1f4073;
  padding: 40px 50px 36px;
  font-weight: bold;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media screen and (max-width: 1024px) {
  .contact-page__info {
    padding: 20px 25px 36px;
  }
}
@media screen and (max-width: 834px) {
  .contact-page__info {
    padding: 20px 15px;
    max-width: 400px;
  }
}
.contact-page__info-left {
  width: calc(100% - 312px);
  position: relative;
  top: -8px;
}
@media screen and (max-width: 834px) {
  .contact-page__info-left {
    width: 100%;
    margin-bottom: 20px;
  }
}
.contact-page__info-ttl {
  font-size: 2.4rem;
  font-weight: 600;
  color: #1f4073;
  margin-bottom: 8px;
  letter-spacing: 0.5px;
}
@media screen and (max-width: 834px) {
  .contact-page__info-ttl {
    font-size: 2rem;
    letter-spacing: 0;
    text-align: center;
  }
}
.contact-page__info-dots {
  line-height: 1.2;
}
.contact-page__info-dots li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 15px;
}
.contact-page__info-dots li:last-of-type {
  margin-bottom: 0;
}
.contact-page__info-dots li:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 10px;
  height: 10px;
  background: #1f4073;
  border-radius: 50%;
  top: 5px;
  left: 0;
}
.contact-page__info-right {
  width: 312px;
  background: rgba(214, 225, 236, 0.7);
  background-blend-mode: multiply;
  border-radius: 5px;
  padding: 20px 20px;
  text-align: center;
}
@media screen and (max-width: 834px) {
  .contact-page__info-right {
    width: 100%;
    padding: 15px;
  }
}
.contact-page__info-label {
  display: block;
}
.contact-page__info-tel {
  text-align: center;
  display: block;
}
.contact-page__info-tel img {
  height: 21px;
  width: auto;
}
.contact-page__thanks {
  text-align: center;
  margin-bottom: clamp(3em, 8vw, 7.4em);
  font-weight: 500;
}
.contact-page__thanks-txt-lead {
  font-size: 2.4rem;
  font-weight: 600;
  display: block;
  margin-bottom: 15px;
  color: #1f4073;
}
@media screen and (max-width: 834px) {
  .contact-page__thanks-txt-lead {
    font-size: 2rem;
    line-height: 1.5;
  }
}

.wpcf7-not-valid-tip {
  font-size: 1.4rem;
  margin-top: 10px;
}

.required, .any {
  width: 40px;
  height: 25px;
  color: #fff;
  background: #cc223a;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 500;
  margin-left: 8px;
}
@media screen and (max-width: 834px) {
  .required, .any {
    font-size: 1.2rem;
    height: 20px;
  }
}

.any {
  background: #1f4073;
}

.radio {
  display: inline-block;
  position: relative;
  padding-left: 50px;
  cursor: pointer;
  user-select: none;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media screen and (max-width: 834px) {
  .radio {
    padding-left: 40px;
  }
}
.radio:hover {
  opacity: 0.7;
}
.radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.radio .checkmark {
  position: absolute;
  top: -3px;
  left: 0;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: #fff;
}
@media screen and (max-width: 834px) {
  .radio .checkmark {
    width: 30px;
    height: 30px;
    top: -1px;
  }
}
.radio .checkmark:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 12px;
  top: 11px;
  border-radius: 50%;
  background: #1f4073;
  transform: scale(0);
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media screen and (max-width: 834px) {
  .radio .checkmark:after {
    width: 12px;
    height: 12px;
    left: 9px;
    top: 9px;
  }
}
.radio input:checked ~ .checkmark:after {
  transform: scale(1);
}

.wpcf7-radio {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 13px 40px;
}
.wpcf7-radio .wpcf7-list-item {
  display: inline-block;
  position: relative;
  padding-left: 50px;
  cursor: pointer;
  user-select: none;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-left: 0;
}
@media screen and (max-width: 834px) {
  .wpcf7-radio .wpcf7-list-item {
    padding-left: 40px;
  }
}
.wpcf7-radio .wpcf7-list-item:hover {
  opacity: 0.7;
}
.wpcf7-radio .wpcf7-list-item input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.wpcf7-radio .wpcf7-list-item-label {
  cursor: pointer;
  font-weight: 700;
}
.wpcf7-radio .wpcf7-list-item-label:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 40px;
  height: 40px;
  top: -3px;
  left: 0;
  border-radius: 50%;
  background: #fff;
}
@media screen and (max-width: 834px) {
  .wpcf7-radio .wpcf7-list-item-label:before {
    width: 30px;
    height: 30px;
    top: -1px;
  }
}
.wpcf7-radio .wpcf7-list-item-label:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 12px;
  top: 9px;
  border-radius: 50%;
  background: #1f4073;
  transform: scale(0);
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media screen and (max-width: 834px) {
  .wpcf7-radio .wpcf7-list-item-label:after {
    width: 12px;
    height: 12px;
    left: 9px;
    top: 9px;
  }
}
.wpcf7-radio .wpcf7-list-item input:checked + span:after {
  transform: scale(1);
}

.file-note {
  font-size: 1.4rem;
  display: block;
  margin-bottom: 2px;
}

.file-upload p {
  padding: 15px 20px;
  border-radius: 5px;
  background: #fff;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 20px;
  position: relative;
}
@media screen and (max-width: 640px) {
  .file-upload p {
    gap: 10px;
  }
}
@media screen and (max-width: 374px) {
  .file-upload p {
    font-size: 1.2rem;
  }
}
.file-upload:not(:last-child) {
  margin-bottom: 30px;
}
@media screen and (max-width: 834px) {
  .file-upload:not(:last-child) {
    margin-bottom: 30px;
  }
}
.file-upload label {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  max-width: 185px;
  height: 40px;
  background: #fff;
  color: #1f4073;
  border: 1px solid #1f4073;
  border-radius: 4px;
  font-weight: 700;
  transition: 0.3s ease;
  cursor: pointer;
}
@media screen and (max-width: 834px) {
  .file-upload label {
    max-width: 120px;
    height: 38px;
  }
}
@media screen and (max-width: 374px) {
  .file-upload label {
    width: 120px;
    height: 40px;
  }
}
@media (any-hover: hover) {
  .file-upload label:hover {
    opacity: 0.7;
  }
}
.file-upload input {
  opacity: 0;
  position: absolute;
  z-index: 10;
  top: 21px;
}
.file-upload > span:last-child {
  word-break: break-all;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.4;
  max-height: 45px;
  width: calc(100% - 185px);
}
@media screen and (max-width: 834px) {
  .file-upload > span:last-child {
    max-height: 39px;
    width: calc(100% - 150px);
  }
}
.file-upload .wpcf7-form-control-wrap {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.file-upload .wpcf7-form-control-wrap .wpcf7-not-valid-tip {
  position: absolute;
  bottom: -40px;
}

.wpcf7-response-output {
  display: none;
}
@use "vars" as *;

/* ====================================================
Header
==================================================== */
.main-header{
	&__content{
		display: none;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		height: 100dvh;
		z-index: 99;
		background: url('../img/index/bg-main.jpg') center repeat;
		background-size: contain;
		background-attachment: fixed;
		overflow-y: auto;
	}
	&__logo{
		width: 393px;
		margin-bottom: 121px;
		display: block;
		@include mq(lp){
			width: 350px;
			margin-bottom: 40px;
		}
		@include mq(xl){
			width: 280px;
		}
		@include mq(){
			width: 230px;
		}
		img{
			width: 100%;
		}
	}
	&__logo-child{
		position: absolute;
		top: 22px;
		left: calc((100% - 1500px)/2);
		width: 260px;
		display: block;
		z-index: 10;
		@media screen and (max-width: 1500px){
			left: 5%;
		}
		@include mq{
			width: 130px;
			top: 15px;
		}
	}
	&__navGlobal{
		@include flex(start,center,wrap);
		&-left{
			width: 50%;
			padding: 47px 100px 50px;
			@include mq(lp){
				padding: 35px 80px;
			}
			@include mq(xl){
				padding: 35px 30px;
			}
			@include mq(){
				width: 100%;
				padding: 75px 30px;
			}
		}
		&-right{
			width: 50%;
			@include mq(){
				display: none;
			}
			img{
				width: 100%;
				object-fit: cover;
				height: 100vh;
				height: 100dvh;
			}
		}
	}
	&__menu{
		margin-bottom: 96px;
		@include mq(lp){
			margin-bottom: 40px;
		}
		&-item{
			&:not(:last-child){
				margin-bottom: 40px;
				@include mq(lp){
					margin-bottom: 25px;
				}
				@include mq(){
					margin-bottom: 30px;
				}
			}
			a{
				display: block;
				img{
					height: 26px;
					width: auto;
					@include mq(lp){
						height: 22px;
					}
					@include mq(xl){
						height: 20px;
					}
					@include mq(){
						height: 18px;
					}
				}
			}
		}
	}
	&__share{
		@include flex();
		gap: 0 28px;
		@include mq(){
			gap: 0 30px;
		}
		&-item{
			a{
				display: block;
				color: #355381;
				@include font-size(37);
				@include mq(lp){
					@include font-size(32);
				}
				@include mq(){
					@include font-size(28);
				}
			}
		}
	}
	&__mobile-icon{
		position: fixed;
		top: 0;
		right: 0;
		width: 90px;
		height: 90px;
		background: #1f4073;
		z-index: 102;
		cursor: pointer;
		@include mq(lp){
			width: 60px;
			height: 60px;
		}
		@include mq(){
			width: 50px;
			height: 50px;
		}
		&-border{
			position: absolute;
			top: 50%;
			left: 20px;
			display: block;
			width: 30px;
			height: 3px;
			user-select: none;
			transition: background-color 0.3s;
			background: #fff;
			@include mq(lp){
				width: 25px;
				left: 11px;
			}
			@include mq(){
				top: 47%;
				height: 2px;
				left: 12px;
				width: 17px;
			}
			&:nth-child(1){
				margin-top: 14px;
				margin-left: 0;
				width: 50px;
				@include mq(lp){
					width: 40px;
					margin-top: 10px;
				}
				@include mq(){
					margin-top: 7px;
					width: 26px;
				}
			}
			&:nth-child(3){
				margin-top: -14px;
				margin-left: 0;
				width: 50px;
				@include mq(lp){
					width: 40px;
					margin-top: -10px;
				}
				@include mq(){
					width: 26px;
					margin-top: -7px;
				}
			}
		}
	}
}


// mobile menu btn animation
.main-header__mobile-icon-border:nth-child(1) {
	-webkit-animation: click-header-sp-bar-1-close 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards;
	animation: click-header-sp-bar-1-close 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards
}

@-webkit-keyframes click-header-sp-bar-1-close {
	0% {
		transform: translateY(5px) rotate(45deg)
	}
	50% {
		transform: translateY(5px) rotate(0)
	}
	100% {
		transform: translateY(0) rotate(0)
	}
}
@keyframes click-header-sp-bar-1-close {
	0% {
		transform: translateY(5px) rotate(45deg)
	}
	50% {
		transform: translateY(5px) rotate(0)
	}
	100% {
		transform: translateY(0) rotate(0)
	}
}
.main-header__mobile-icon-border:nth-child(2) {
	-webkit-animation: click-header-sp-bar-2-close 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards;
	animation: click-header-sp-bar-2-close 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards
}
@-webkit-keyframes click-header-sp-bar-2-close {
	0% {
		opacity: 0
	}
	20% {
		opacity: 0
	}
	100% {
		opacity: 1
	}
}
@keyframes click-header-sp-bar-2-close {
	0% {
		opacity: 0
	}
	20% {
		opacity: 0
	}
	100% {
		opacity: 1
	}
}
.main-header__mobile-icon-border:nth-child(3) {
	-webkit-animation: click-header-sp-bar-3-close 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards;
	animation: click-header-sp-bar-3-close 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards
}
@-webkit-keyframes click-header-sp-bar-3-close {
	0% {
		transform: translateY(-5px) rotate(-45deg)
	}
	50% {
		transform: translateY(-5px) rotate(0)
	}
	100% {
		transform: translateY(0) rotate(0)
	}
}
@keyframes click-header-sp-bar-3-close {
	0% {
		transform: translateY(-5px) rotate(-45deg)
	}
	50% {
		transform: translateY(-5px) rotate(0)
	}
	100% {
		transform: translateY(0) rotate(0)
	}
}

//menu opened		
.mobile-close .main-header__mobile-icon-border:nth-child(1) {
	margin-top: -5px;
	-webkit-animation: click-header-sp-bar-1-open 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards;
	animation: click-header-sp-bar-1-open 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards
}
@-webkit-keyframes click-header-sp-bar-1-open {
	0% {
		transform: translateY(0) rotate(0)
	}
	50% {
		transform: translateY(5px) rotate(0)
	}
	100% {
		transform: translateY(5px) rotate(45deg)
	}
}
@keyframes click-header-sp-bar-1-open {
	0% {
		transform: translateY(0) rotate(0)
	}
	50% {
		transform: translateY(5px) rotate(0)
	}
	100% {
		transform: translateY(5px) rotate(45deg)
	}
}
.mobile-close .main-header__mobile-icon-border:nth-child(2) {
	-webkit-animation: click-header-sp-bar-2-open 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards;
	animation: click-header-sp-bar-2-open 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards
}
@-webkit-keyframes click-header-sp-bar-2-open {
	0% {
		opacity: 1
	}
	80% {
		opacity: 0
	}
	100% {
		opacity: 0
	}
}
@keyframes click-header-sp-bar-2-open {
	0% {
		opacity: 1
	}
	80% {
		opacity: 0
	}
	100% {
		opacity: 0
	}
}
.mobile-close .main-header__mobile-icon-border:nth-child(3) {
	margin-top: 5px;
	-webkit-animation: click-header-sp-bar-3-open 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards;
	animation: click-header-sp-bar-3-open 0.8s cubic-bezier(0.45, 0.12, 0.04, 0.96) forwards
}
@-webkit-keyframes click-header-sp-bar-3-open {
	0% {
		transform: translateY(0) rotate(0)
	}
	50% {
		transform: translateY(-5px) rotate(0)
	}
	100% {
		transform: translateY(-5px) rotate(-45deg)
	}
}
@keyframes click-header-sp-bar-3-open {
	0% {
		transform: translateY(0) rotate(0)
	}
	50% {
		transform: translateY(-5px) rotate(0)
	}
	100% {
		transform: translateY(-5px) rotate(-45deg)
	}
}


@use "vars" as *;

.main-footer{
	position: relative;
	z-index: 2;
	&__brand{
		padding: 81px 0;
		background: rgba(#D6E1EC,0.5);
		@include mq(){
			padding: 50px 0;
		}
		&-list{
			margin: 0 -15px;
			@include flex(center,0,wrap);
			gap: 31px 0;
			@include mq(xl){
				margin: 0 -10px;
				gap: 20px 0;
			}
			@include mq(){
				gap: 14px 0;
				margin: 0 -7px;
			}
		}
		&-item{
			padding: 0 15px;
			width: 33.33%;
			@include mq(xl){
				padding: 0 10px;
			}
			@include mq(){
				width: 50%;
				padding: 0 7px;
			}
			@include mq(sm){
				width: 100%;
			}
			a{
				display: block;
				@include mq(){
					max-width: 400px;
					margin: 0 auto;
				}
				img{
					width: 100%;
				}
			}
		}
	}
	&__content{
		padding: 116px 0 79px;
		@include mq(){
			padding: 60px 0;
		}
	}
	&__nav{

	}
	&__share{
		@include flex(center);
		gap: 0 29px;
		margin-bottom: 50px;
		align-items: center;
		@include mq(){
			gap: 0 25px;
			margin-bottom: 35px;
		}
		&-item{
			a{
				display: block;
				color: #355381;
				@include font-size(36);
				@include mq(xl){
					@include font-size(30);
				}
				@include mq(){
					@include font-size(24);
				}
			}
		}
	}
	&__menu{
		@include flex(center,0,wrap);
		gap: 20px 45px;
		margin-bottom: 60px;
		@include mq(xl){
			gap: 20px 30px;
		}
		@include mq(){
			margin-bottom: 35px;
		}
		&-item{
			a{
				display: block;
				img{
					width: auto;
					height: 14px;
					@include mq(xl){
						height: 13px;
					}
					@include mq(){
						height: 12px;
					}
				}
			}
		}
	}
	&__contact{
		@include flex(center,0,wrap);
		gap: 20px 20px;
		@include mq(){
			gap: 10px 20px;
		}
		&-item{
			max-width: 390px;
			width: 100%;
			@include mq(xl){
				max-width: 300px;
			}
			@include mq(){
				max-width: 280px;
			}
			a{
				display: block;
				img{
					width: 100%;
				}
			}
		}
	}
	&__copyright{
		padding: 20px 10px;
		letter-spacing: 0.75px;
		text-align: center;
		color: #fff;
		background: #355381;
		font-weight: 500;
		@include font-size(13);
		@include mq(xl){
			letter-spacing: 0;
			padding: 15px 10px;
		}
		@include mq(){
			@include font-size(12);
		}
	}
}
@use "vars" as *;

section{
	position: relative;
}
.sec-marc,.sec-news,.sec-todays,.sec-journal {
	z-index: 2;
}

.mv-index{
	margin-bottom: -80px;
	@include flex();
	@include mq(xl-min){
		height: 140vh;
		height: 140dvh;
	}
	@include mq(){
		margin-bottom: -210px;
	}
	&__left{
		width: calc(100% - 90px);
		overflow: hidden;
		position: relative;
		left: 0;
		top: 0;
		z-index: 2;
		@include mq(lp){
			width: calc(100% - 60px);
		}
		@include mq(){
			width: 100%;
			margin-top: -5px;
		}
	}
	&__slider{
		@include mq(md-min){
			height: 100%;
		}
		//min-height: 1125px;
		@include mq(lp){
			//min-height: 743px;
		}
		@include mq(xl){
			min-height: 1119px;
			//min-height: 1529px;
		}
		@include mq(sm){
			//min-height: 723px;
			//min-height: 1119px;
		}
		&-list{
			width: max-content;
			position: absolute;
			top: 0;
			left: 0;
			white-space: nowrap;
			display: flex;
			overflow: hidden;
			gap: 0 50px;
			animation: infinity-scroll-left 80s infinite linear 0.5s both;
			@include mq(){
				gap: 0 40px;
			}
		}
		&-item{
			//width: 90%;
			@include mq(xl-min){
				width: auto;
				height: 140vh;
				height: 140dvh;
			}
			@include mq(xl){
				width: 1200px;
			}
			@include mq(){
				width: 650px;
			}
			@include mq(sm){
				//width: 650px;
			}
			
			img {
				@include mq(xl-min){
					height: 100%;
					object-fit: cover;
				}
				@include mq(){
					width: 100%;
				}
			}
		}
	}
	&__ttl{
		width: 65%;
		position: absolute;
		//top: 44.2%;
		top: 40.2%;
		transform: translateY(-50%);
		left: 89px;
		z-index: 20;
		max-width: 1007px;
		@include mq(lp){
			width: 48%;
			//top: 46.2%;
			top: 36.2%;
			left: 50px;
		}
		@include mq(){
			width: 83%;
			//top: 57.7%;
			top: 35%;
			left: 40px;
			max-width: 500px;
		}
		@include mq(sm){
		}
		img{
			width: 100%;
		}
	}
	&__right{
		position: relative;
		width: 90px;
		@include flex(0,start);
		align-content: start;
		flex-wrap: wrap;
		z-index: 3;
		@include mq(md-min){
			height: 100vh;
			height: 100dvh;
		}
		@include mq(lp){
			width: 60px;
		}
		@include mq(){
			display: none;
		}
		&-fixed{
			position: fixed;
			right: 0;
			width: 90px;
			top: 0;
			padding-top: 130px;
			z-index: 50;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: stretch;
			//height: 100vh;
			height: 80vh;
			@include mq(lp){
				padding-top: 100px;
				width: 60px;
			}
			@include mq(xl){
				width: 40px;
			}
		}
	}
	&__share{
		width: 100%;
		&-item{
			@include flex(center,center);
			text-align: center;
			&:not(:last-child){
				margin-bottom: 28px;
			}
			a{
				writing-mode: tb-rl;
				display: block;
				color: #1f4073;
				@include font-size(24);
			}
		}
	}
	&__copyright{
		width: 100%;
		writing-mode: tb-rl;
		color: #1f4073;
		//font-weight: 500;
		font-weight: 700;
		display: flex;
		letter-spacing: 0.7px;
    	align-items: center;
		margin-bottom: 30px;
		@include font-size(13);
		@include mq(lp){
			margin-bottom: 30px;
		}
	}
	&__scroll{
		display: flex;
		width: 100%;
		justify-content: center;
		position: absolute;
		bottom: 0;
		@include mq(tb){
			display: none;
		}
		img{
			width: 19.5px;
		}
	}
}

.sec-marc{
	position: relative;
	.inner{
		//padding: 328px 0 474px;
		padding: 30px 0 424px;
		position: relative;
		@include mq(){
			//padding: 211px 0 312px;
			padding: 30px 0 142px;
		}
		@include mq(){
			padding: 30px 0 288px;
		}
	}
}

.marc-img{
	position: absolute;
	img{
		width: 100%;
	}
	&--01{
		width: 262px;
		top: 21px;
		left: 11.8%;
		@include mq(lp){
			width: 220px;
			top: 50px;
		}
		@include mq(){
			width: 35%;
			top: 46px;
			max-width: 170px;
			left: 8%;
		}
	}
	&--02{
		width: 228px;
		right: -149px;
		top: 112px;
		@include mq(lp){
			width: 180px;
			right: 0;
			top: 42px;
		}
		@include mq(){
			width: 30.2%;
			right: -5.5%;
			top: 91px;
			max-width: 170px;
		}
	}
	&--03{
		width: 260px;
		left: -153px;
		bottom: 221px;
		@include mq(lp){
			width: 200px;
			left: -33px;
			bottom: 221px;
		}
		@include mq(){
			width: 34%;
			left: -5.5%;
			bottom: 164px;
			max-width: 190px;
		}
	}
	&--04{
		width: 374px;
		left: 195px;
		bottom: 71px;
		@include mq(lp){
			width: 300px;
			left: 225px;
		}
		@include mq(){
			width: 50%;
			left: 38%;
			bottom: 41px;
			max-width: 250px;
		}
	}
}

.marc-loop{
	width: max-content;
	position: absolute;
	//top: 39.9%;
	top: 15%;
	left: 0;
	transform: translateY(-50%);
	white-space: nowrap;
	display: flex;
	overflow: hidden;
	gap: 0 50px;
	animation: infinity-scroll-left 80s infinite linear 0.5s both;
	opacity: 0.7;
	@include mq(lp){
		//top: 37.9%;
	}
	@include mq(){
		//top: 31.6%;
		top: -3%;
	}
	@include mq(sm){
		//top: 26.6%;
	}
	&__item{
		@include mq(lp){
			width: 2000px;
		}
		@include mq(){
			width: 2400px;
		}
		img{
			width: 100%;
		}
	}
}

.marc-flex{
	@include flex(0,start);
	position: relative;
	//top: -50px;
	@include mq(){
		flex-wrap: wrap-reverse;
		//top: -180px;
	}
	&__ct{
		width: 57.5%;
		padding-right: 95px;
		margin-top: -28px;
		@include mq(lp){
			margin-top: 0;
			padding-right: 40px;
		}
		@include mq(){
			padding-right: 0;
			width: 100%;
		}
		.c-txt__01{
			color: #1f4073;
			line-height: 2.22;
			margin-bottom: 65px;
			letter-spacing: -0.75px;
			font-weight: 700;
			@include mq(xl){
				line-height: 2;
				letter-spacing: 0;
				margin-bottom: 30px;
			}
			@include mq(){
				margin-bottom: 43px;
				line-height: 1.964;
			}
		}
	}
	&__img{
		width: 42.5%;
		@include mq(){
			width: 75.5%;
			margin: 0 auto 40px;
			max-width: 400px;
		}
		img{
			width: 100%;
		}
	}
}

.sec-news{
	//padding: 80px 0;
	padding: 60px 0 80px;
	@include mq(){
		//padding: 20px 0;
		padding: 0 0 20px;
	}
	.inner{
		width: 90%;
		max-width: 1500px;
		border-radius: 10px;
		background: url('../img/index/bg-main.jpg') center no-repeat;
		padding: 82px 20px 111px;
		position: relative;
		z-index: 2;
		@include mq(lp){
			padding: 82px 30px;
		}
		@include mq(xl){
			padding: 60px 25px;
		}
		@include mq(){
			padding: 50px 20px;
		}
		@include p-before(100%,100%){
			background: rgb(190 212 235 / 40%);
			top: 0;
			left: 0;
			z-index: -1;
		}
	}
}

.news-list{
	max-width: 1200px;
	width: 100%;
	margin: 0 auto;
	@include flex(between);
	@include mq(){
		flex-wrap: wrap;
	}
	&__item{
		width: 50%;
		padding-right: 60px;
		@include mq(lp){
			padding-right: 30px;
		}
		@include mq(){
			padding-right: 0;
			width: 100%;
		}
		&:nth-child(1){
			border-right: 3px solid #1f4073;
			@include mq(xl){
				border-width: 2px;
			}
			@include mq(){
				border-right: 0;
				margin-bottom: 40px;
				padding-bottom: 40px;
				border-bottom: 1px solid #1f4073;
			}
		}
		&:nth-child(2){
			padding-left: 60px;
			padding-right: 0;
			@include mq(lp){
				padding-left: 30px;
			}
			@include mq(){
				padding-left: 0;
			}
		}
	}
	&__posts{
		margin-bottom: 40px;
		@include mq(){
			margin-bottom: 25px;
		}
		&-item{
			border-bottom: 1px dotted #1f4073;
			&:nth-child(1){
				border-top: 1px dotted #1f4073;
			}
			a{	
				display: block;
				padding: 27px 20px 23px;
				@include mq(xl){
					padding: 20px 5px 16px;
				}
			}
			time{
				line-height: 1.2;
				display: block;
				color: #1f4073;
				font-weight: 500;
				margin-bottom: 10px;
				@include font-size(14);
				font-feature-settings: "palt";
				@include mq(xl){
					margin-bottom: 5px;
				}
				@include mq(){
					@include font-size(12);
				}
			}
			p{
				color: #111;
			}
		}
		&-inner{
			display: block;
			padding: 27px 20px 23px;
			@include mq(xl){
				padding: 20px 5px 16px;
			}
		}
	}
	&__schedule{
	    margin-bottom: 99px;
		@include mq(){
			margin-bottom: 25px;
		}
		&-item{
			border-bottom: 1px dotted #1f4073;
			&:nth-child(1){
				border-top: 1px dotted #1f4073;
			}
		}
		a,
		&-inner{
			@include flex();
			padding: 29px 20px 21px;
			@include mq(xl){
				padding: 20px 5px 16px;
			}
		}
		time{
			width: 112px;
			line-height: 1.2;
			display: block;
			color: #1f4073;
			font-weight: 500;
			padding-top: 7px;
			@include font-size(14);
			font-feature-settings: "palt";
			@include mq(xl){
				width: 90px;
			}
			@include mq(){
				@include font-size(12);
			}
		}
		&-ct{
			width: calc(100% - 112px);
			@include mq(xl){
				width: calc(100% - 90px);
			}
			span{
				background: #cc223a;
				display: inline-block;
				font-weight: 500;
				color: #fff;
				line-height: 1;
				margin-bottom: 9px;
				padding: 7px 12px 4px;
				@include font-size(13);
				@include mq(xl){
					padding: 5px 10px 4px;
				}
				@include mq(){
					@include font-size(12);
				}
			}
			p{
				color: #111;
				display: block;
				letter-spacing: 0.5px;
				@include mq(xl){
					letter-spacing: 0;
				}
			}
		}
	}
}

.sec-todays{
	//padding: 69px 0 150px;
	padding: 60px 0 80px;
	@include mq(xl){
		//padding: 60px 0 80px;
	}
	@include mq(){
		//padding: 50px 0 0;
		padding: 30px 0;
	}
	.c-ttl__01{
		text-align: center;
		transform: translateX(-76px);
		margin-bottom: 60px;
		@include mq(xl){
			margin-bottom: 40px;
			transform: translateX(0);
		}
		@include mq(){
			margin-bottom: 30px;
		}
	}
}

.todays-box{
	padding: 73px 57px 60px 97px;
    border: 3px solid #1f4073;
    border-radius: 20px;
	background-color: rgba(255, 255, 255, 0.5);
	@include mq(xl){
		padding: 50px 30px;
		border-width: 2px;
	}
	@include mq(){
		padding: 40px 20px;
		border-width: 1px;
	}
	&__scroll{
		height: 449px;
		overflow-y: auto;
	}
	&__heading{
		text-align: center;
		font-weight: 600;
		margin-right: 40px;
		color: #333333;
		padding-bottom: 21px;
		border-bottom: 1px solid #1F4073;
		@include font-size(30);
		@include mq(xl){
			padding-bottom: 10px;
			@include font-size(24);
		}
		@include mq(){
			margin-bottom: 10px;
			margin-right: 0;
			@include font-size(22);
		}
	}
	&__date{
		display: block;
		text-align: right;
		margin-bottom: 36px;
		letter-spacing: 1px;
		color: #cc223a;
		margin-top: 12px;
		font-weight: bold;
		@include font-size(16);
		@include mq(xl){
			margin-bottom: 24px;
		}
		@include mq(){
			margin-top: 0;
			letter-spacing: 0;
			margin-bottom: 15px;
			@include font-size(14);
		}
	}
	&__ct{
		p{
			letter-spacing: 0.25px;
			line-height: 2;
			@include mq(){
				letter-spacing: 0;
				line-height: 1.6;
			}
			&:not(:last-child){
				//margin-bottom: 32px;
				@include mq(){
					//margin-bottom: 20px;
				}
			}
		}
	}
	.mCSB_scrollTools{
		width: 4px !important;
		@include mq(){
			width: 2px !important;
		}
	}
	.mCSB_draggerRail{
		background: none;
	}
	.mCSB_draggerContainer{
		width: 4px;
		background: #9ea9d6;
		@include mq(){
			width: 2px !important;
		}
	}
	.mCSB_dragger_bar{
		width: 4px !important;
		border-radius: 0 !important;
		background: #1f4073 !important;
		@include mq(){
			width: 2px !important;
		}
	}
	.mCSB_inside>.mCSB_container{
		margin-right: 38px;
		@include mq(){
			margin-right: 20px;
		}
	}
}

.sec-journal{
	padding: 210px 0 150px;
	//padding: 180px 0 150px;
	//background: rgba(256,256,256,0.3);
	background: url(../img/index/bg-noise.jpg) 100% repeat;
	@include mq(xl){
		padding: 150px 0 100px;
	}
	@include mq(){
		padding: 60px 0;
		padding: 50px 0 60px;
	}
	&:before {
		content: "";
		position: absolute;
		top: 50px;
		display: block;
		width: 100%;
		height: 100%;
		//background: url("../img/index/img-map.png") top 11.5% left 80% no-repeat;
		background: url("../img/index/img-map.png") top 0 left 80% no-repeat;
		background-size: 71%;
		@include mq(xl){
			background-size: 100%;
		}
		@include mq(){
			//background: url("../img/index/img-map.png") top 0 left 80% no-repeat;
			//background-size: 71%;
			background: url("../img/index/img-map.png") top 0 left 20% no-repeat;
			background-size: 120%;
			top: 10px;
		}
	}
	.c-ttl__01{
		text-align: center;
		margin-bottom: 81px;
		transform: translateX(31px);
		@include mq(xl){
			transform: translateX(0);
		}
		@include mq(){
			margin-bottom: 38px;
		}
	}
	.inner{
		max-width: 1500px;
	}
}

.journal-flex{
	max-width: 890px;
	width: 100%;
	margin: 0 auto 76px;
	@include mq(){
		max-width: 81%;
		//margin: 0 auto 61px;
		margin: 0 auto 40px;
	}
	.c-btn__02{
		padding-right: 18px;
		margin-right: 4px;
	}
	a{
		@include flex();
		@include mq(){
			flex-wrap: wrap;
		}
	}
	&__img{
		overflow: hidden;
		width: 351px;
		position: relative;
		border-radius: 10px;
		border: 2px solid #1F4073;
		@include mq(xl){
			width: 280px;
		}
		@include mq(){
			width: 100%;
			margin-bottom: 15px;
		}
		img{
			width: 100%;
		}
		&-thumb{
			@include aspect-ratio(351, 251);
		}
	}
	&__new{
		display: inline-block;
		background: #cc223a;
		padding: 5px 10px;
		color: #fff;
		font-weight: 600;
		position: absolute;
		//top: 3px;
		//left: 3px;
		border-radius: 6px 0 10px 0;
		@include font-size(20);
		z-index: 2;
		@include mq(){
			padding: 3px 9px 2px 6px;
			@include font-size(16);
		}
	}
	&__ct{
		padding-left: 52px;
		margin-top: -1px;
		width: calc(100% - 351px);
		@include mq(xl){
			padding-left: 30px;
			width: calc(100% - 280px);
		}
		@include mq(){
			padding-left: 0;
			width: 100%;
			.c-al--r{
				text-align: center !important;
			}
		}
		.c-txt__01{
			margin-bottom: 45px;
			color: #111;
			display: -webkit-box;
			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			max-height: 108px;
			@include mq(xl){
				max-height: 102px;
				margin-bottom: 20px;
			}
			@include mq(){
				max-height: 88px;
				margin-bottom: 24px;
				-webkit-line-clamp: 4;
				line-height: 1.571;
			}
		}
	}
	&__date{
		color: #cc223a;
		font-weight: 600;
		display: block;
		margin-bottom: 11px;
		//letter-spacing: 1px;
		@include font-size(20);
		font-feature-settings: "palt";
		@include mq(xl){
			//letter-spacing: 0;
			margin-bottom: 6px;
			@include font-size(18);
		}
		@include mq(){
			margin-bottom: -2px;
			@include font-size(15);
		}
	}
	&__ttl{
		margin-bottom: 13px;
		font-weight: 600;
		color: #1f4073;
		@include font-size(24);
		@include mq(xl){
			margin-bottom: 10px;
			@include font-size(22);
		}
		@include mq(){
			margin-bottom: 7px;
			@include font-size(17);
		}
	}
}

.journal-menu{
	@include flex(center,0,wrap);
	gap: 0 20px;
	border-bottom: 3px solid #1f4073;
	@include mq(xl){
		gap: 10px 10px;
	}
	@include mq(){
		border-bottom: 0;
		gap: 10px 14px;
		padding: 0 8px 28px;
	}
	&__item{
		@include mq(){
			width: calc(50% - 10px);
		}
		a{
			text-align: center;
			display: block;
			padding: 10px 10px 5px;
			border-radius: 5px 5px 0 0;
			min-width: 200px;
			background: #1f4073;
			color: #fff;
			font-weight: bold;
			@include font-size(18);
			@include mq(xl){
				min-width: 150px;
			}
			@include mq(){
				padding: 12px 10px 8px;
				border-radius: 5px;
				@include font-size(16);
			}
			@include hover{
				opacity: 1;
				background: #cc223a;
				position: relative;
				transition: all 0.3s;
				@include p-before(100%,3px){
					background: #cc223a;
					bottom: -3px;
					left: 0;
					transition: all 0.3s;
				}
			}
		}
		&.active{
			a{
				background: #cc223a;
				position: relative;
				transition: all 0.3s;
				@include p-before(100%,3px){
					background: #cc223a;
					bottom: -3px;
					left: 0;
					transition: all 0.3s;
					@include mq(){
						display: none;
					}
				}
			}
		}
	}
}

.journal-list{
	background: rgba(#fff,0.6);
	padding: 60px 90px 71px;
	@include flex(0,0,wrap);
	gap: 73px 0;
    margin-bottom: 83px;
    border-radius: 0 0 15px 15px;
	position: relative;
	z-index: 3;
	@include mq(lp){
		padding: 60px 50px 71px;
	}
	@include mq(xl){
		padding: 60px 30px;
		margin-bottom: 50px;
	}
	@include mq(){
		margin: 0 -6%;
		padding: 39px 25px;
		background: none;
		width: 112%;
		gap: 48px 0;
		border-top: 3px solid #1f4073;
	}
	display: none;
	&.active {
		display: flex;
	}
	&__item{
		margin-right: 66px;
		width: calc(33.33% - 44px);
		@include mq(xl){
			margin-right: 30px;
			width: calc(33.33% - 20px);
		}
		@include mq(){
			margin-right: 30px;
			width: calc(50% - 15px);
			&:nth-child(2n){
				margin-right: 0;
			}
		}
		@include mq(sm){
			width: 100%;
			margin-right: 0 !important;
		}
		@include mq(md-min){
			&:nth-child(3n){
				margin-right: 0;
			}
		}
		a{
			display: block;
            color: #111111;
		}
		p{
			color: #111;
				overflow: hidden;
			  display: -webkit-box;
			  text-overflow: ellipsis;
			  -webkit-box-orient: vertical;
			  -webkit-line-clamp: 3;
			@include mq(){
				line-height: 1.73;
			}
		}
	}
	&__img{
		max-width: 300px;
		margin: 0 auto 26px;
		@include aspect-ratio(1,1);
		border-radius: 10px;
		border: 3px solid #1f4073;
		@include mq(){
			margin-bottom: 28px;
			max-width: 275px;
		}
	}
	&__time{
		display: block;
		color: #cc223a;
		font-weight: 600;
		margin-bottom: 13px;
		@include font-size(18);
		font-feature-settings: "palt";
		@include mq(xl){
			margin-bottom: 10px;
			@include font-size(17);
		}
		@include mq(){
			margin-bottom: 8px;
			@include font-size(16);
		}
	}
	&__ttl{
		color: #111;
		font-weight: 600;
	    margin-bottom: 17px;
		@include font-size(20);
		@include mq(xl){
			margin-bottom: 10px;
			@include font-size(18);
		}
		@include mq(){
			margin-bottom: 16px;
			@include font-size(16);
		}
	}
    &__fav{
        text-align: right;
        .wpulike{
            margin: 0 !important;
        }
        .wp_ulike_general_class{
            border: none !important;
            padding: 0 !important;
            box-shadow: none !important;
            button{
                color: #1f4073;
                font-weight: 600;
                @include font-size(14);
                &::after{
                    background: url(../img/common/heart-off.png) no-repeat 0 0 / 100% auto !important;
                    width: 20px !important;
                    height: 20px !important;
                }
                &.wp_ulike_btn_is_active{
                    &::after{
                        background-image: url(../img/common/heart.png) !important;
                        filter: none !important;
                    }
                }
            }
            .count-box{
                color: #08466d !important;
            }
        }
    }
}

@keyframes infinity-scroll-left {
	from {
	  transform: translateX(0);
	}
	  to {
	  transform: translateX(-50%);
	}
}
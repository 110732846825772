@use "vars" as *;

.c-fw{//font-weight
	&--lt{
		font-weight: 300!important
	}
	&--rg{
		font-weight: 400!important
	}
	&--md{
		font-weight: 500!important
	}
	&--sbd{
		font-weight: 600!important
	}
	&--bd{
		font-weight: 700!important
	}
	&--bk{
		font-weight: 900!important
	}
}

.c-al{
	&--c{
		text-align: center !important;
	}
	
	&--r{
		text-align: right !important
	}
	
	&--l{
		text-align: left !important;
	}
}

.c-bd{
	&--b{
		border: 2px solid #355381;
	}
}


// Image position control
.c-pull{
	&--left{
		margin-left: calc(((100vw - 100%) / 2) * -1)
	}
	&--right{
		margin-right: calc(((100vw - 100%) / 2) * -1)
	}
}

/* ====================================================
Component
==================================================== */
// Common Text style
.c-txt{
	&__01{
		line-height: 2;
		@include font-size(18);
		@include mq(xl){
			@include font-size(17);
		}
		@include mq(){
			@include font-size(14);
		}
	}
	&__02{
		line-height: 1.75;
		@include font-size(16);
		@include mq(xl){
			line-height: 1.6;
			@include font-size(15);
		}
		@include mq(){
			@include font-size(13);
		}
	}
}




//ttl
.c-ttl{
	&__01{
		margin-bottom: 36px;
		@include mq(xl){
			margin-bottom: 20px;
		}
		@include mq(){
			text-align: center;
			margin-bottom: 24px;
		}
		img{
			height: 130px;
			width: auto;
			@include mq(xl){
				height: 85px;
			}
			@include mq(){
				height: 52px;
			}
		}
	}
	&__02{
		margin-bottom: 41px;
		@include mq(xl){
			margin-bottom: 20px;
		}
		img{
			height: 56px;
			width: auto;
			@include mq(xl){
				height: 45px;
			}
			@include mq(){
				height: 42px;
			}
		}
	}
}

//btn
.c-btn{
	&__01{
		position: relative;
		max-width: 302px;
		height: 80px;
		width: 100%;
		color: #fff;
		padding: 12px 31px;
		letter-spacing: 0.5px;
		line-height: 1;
		@include font-size(14);
		//background: rgb(45,100,153);
		//background: linear-gradient(0deg, rgba(45,100,153,1) 87%, rgba(44,95,147,1) 100%);
		background: linear-gradient(180deg, #1f4073 , #2e699f);
		@include flex(0,0,wrap,center);
		@include mq(){
			letter-spacing: 0;
			height: 65px;
			max-width: 275px;
			margin: 0 auto;
			padding: 9px 24px;
			//@include font-size(12);
		}
		span{
			display: block;
			margin-top: 8px;
			width: 100%;
			@include mq(){
				margin-top: 6px;
			}
		}
		img{
			width: 100px;
			@include mq(){
				//width: 85px;
				width: 95px;
			}
		}
		@include p-before(50px,2px){
			background: #fff;
			right: 30px;
			top: 56%;
			transform: translateY(-50%);
			transition: all 0.3s;
			@include mq(){
				width: 30px;
				top: 55%;
				right: 24px;
			}
		}
		@include p-after(10px,2px){
			background: #fff;
		    right: 30px;
			top: 50%;
			transform: rotate(41deg) translateY(-50%);
			top: calc(53% - 2px);
			width: 13px;
			transition: all 0.3s;
			@include mq(){
				width: 10px;
				right: 24px;
			}
		}
		@include hover {
			opacity: 1;
			&::before{
				transition: all 0.3s;
				width: 60px;
				right: 20px;
			}
			&::after{
				right: 20px;
				transition: all 0.3s;
			}
		}
	}
	&__02{
		line-height: 1;
		display: inline-block;
	    padding-right: 41px;
		padding-bottom: 5px;
		position: relative;
		@include p-after(10px,2px){
			background: #1F4073;
			right: -2px;
			transform: rotate(48deg);
			bottom: 5px;
			width: 13px;
			transition: all 0.3s;
		}
		@include p-before(100%,2px){
			background: #1F4073;
			right: 0;
			bottom: 0;
			left: 0;
			transition: all 0.3s;
		}
		img{
			width: auto;
			height: 10px;
		}
		@include hover{
			opacity: 1;
			&::before{
				width: calc(100% + 10px);
				transition: all 0.3s;
			}
			&::after{
				right: -13px;
				transition: all 0.3s;
			}
		}
		&--small{
			img{
				height: 12px;
			}
		}
	}
}


//fade up
.js-scrollin {
	opacity: 0;
	-webkit-transform: translate(0, 50px);
	-ms-transform: translate(0, 50px);
	transform: translate(0, 50px);
	&.active{
		opacity: 1;
		-webkit-transform: translate(0, 0);
		-ms-transform: translate(0, 0);
		transform: translate(0, 0);
		-webkit-transition: 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
		-o-transition: 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
		transition: 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	}
}




@use "vars" as *;


.inner {
	display: block;
	max-width: 1200px;
	width: 90%;
	margin: 0 auto;
	&--1500{
		max-width: 1500px;
	}
}

.ov{
	overflow: hidden;
}

.no-bg{
	&:after{
		display: none;
	}
}

.form-control{
	display: block;
	width: 100%;
	height: 70px;
	background: white;
	font-weight: 500;
	padding: 10px 15px;
	@include font-size(18);
	border-radius: 5px;
	border: none;
	@include mq{
		@include font-size(16);
		height: 50px;
	}
	&:focus{
		border-color: #66afe9;
		outline: 0;
		-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
		box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);
	}
	&--short{
		max-width: 600px;
	}
	&--textarea{
		padding: 20px;
		height: 300px;
		resize: vertical;
		@include mq{
			height: 200px;
		}
	}
}


.js-locked {
	overflow: hidden;
	-ms-touch-action: none;
	touch-action: none;
	body {
		-webkit-overflow-scrolling: auto;
	}
}
.sticky{
	position: sticky;
	top: 0;
}
.page-parts{
	.mv-index__right{
		height: inherit;
	}
}
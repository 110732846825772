@use "vars" as *;



.pc{
	@include mq(){
		display: none !important;
	}
	&-tb{
		@include mq(tb){
			display: none !important;
		}
	}
	&-lg{
		@include mq(lg){
			display: none !important;
		}
	}
	&-sm{
		@include mq(sm){
			display: none !important;
		}
	}
	&-xs{
		@include mq(xs){
			display: none !important;
		}
	}
}
.sp{
	@include mq(md-min){
		display: none !important;
	}
	&-tb{
		@include mq(tb-min){
			display: none !important;
		}
	}
	&-lg{
		@include mq(lg-min){
			display: none !important;
		}
	}
	&-sm{
		@include mq(sm-min){
			display: none !important;
		}
	}
}



// Hover style
.u-fn-hover{
	display: inline-block;
	@include mq(md-min){
		transition: all .2s;
		&:hover{
			opacity: .7;
		}
	}
}

//post style
.post-style {
	ul,ol{
		padding-left: 1.6rem;
	}
	ul li {
		list-style-type: disc;
	}
	ol li {
		list-style-type: decimal;
	}
	h1,h2,h3,h4,h5,h6 {
		font-size: large;
		font-weight: 700;
	}
}
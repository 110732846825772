@use "vars" as *;

html {
	overflow-y: scroll;
	//scroll-behavior: smooth;
	&.noscroll {
		overflow-y: hidden;
	}
}

body {
	color: #111111;
	font-family: $font-jp;
	font-size: 100%;
	-webkit-font-smoothing: antialiased;
	line-height: 1.3;
	//overflow-x: hidden;
	overflow-x: clip;
	//background: url('../img/index/bg-main.jpg') center repeat;
	//background-size: contain;
	//background-attachment: fixed;
	position: relative;
	//z-index: 2;
	@include p-before(100%,100vh){
		height: 100dvh;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1;
		background: url('../img/index/bg-mv.png') center no-repeat;
		background-size: cover;
		pointer-events: none;
	}
	@include p-after(66%,39vw){
		position: fixed;
		bottom: 0;
		right: 0;
		z-index: 1;
		background: url('../img/common/img-earth.png') center no-repeat;
		background-size: contain;
		@include mq(){
			//display: none;
			width: 76%;
			height: 45vw;
		}
	}
}
.bg-main {
	width: 100vw;
	height: 100vh;
	height: 100dvh;
	position: fixed;
	background: url('../img/index/bg-main.jpg') center repeat;
	background-size: contain;
	//background-attachment: fixed;
	z-index: 0;
}
a{
	text-decoration: none;
	@include smooth-transition;
	@include hover{
		opacity: 0.7;
	}
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		display: inline-block;
	}
	&[href^="tel"] {
		@include mq(md-min){
			pointer-events: none;
		}
	}
	
	@include mq(){
		-webkit-tap-highlight-color: transparent;
	}
}







@use 'vars' as *;

.contact-page{
	position: relative;
	z-index: 2;
	padding: clamp(10em,20vw,26.5em) 0 clamp(5em,9vw, 15em);
	&__ttl{
		max-width: 305px;
		margin: 0 auto clamp(5em,9vw,13em);
		@include mq{
			max-width: 220px;
		}
	}
	&__intro{
		text-align: center;
		margin-bottom: 67px;
		font-weight: 700;
		@include mq{
			margin-bottom: 30px;
			text-align: left;
		}
	}
	&__form{
		@include fts(18,36);
		margin-bottom: clamp(5em,7vw,10em);
		@include mq(xl){
			@include font-size(17);
		}
		@include mq(){
			@include font-size(14);
		}
	}
	
	&__dt{
		width: 340px;
		@include flex(0, center);
		padding-top: 17px;
		font-weight: bold;
		@include mq(tb){
			width: 280px;
		}
		@include mq{
			width: 100%;
			padding-top: 0;
			margin-bottom: 10px;
		}
		p{
			@include flex(0, center);
		}
	}
	&__dd{
		width: calc(100% - 340px);
		@include mq(tb){
			width: calc(100% - 280px);
		}
		@include mq{
			width: 100%;
		}
		&--radio{
			padding-top: 20px;
			@include flex(0,0,wrap);
			gap: 13px 40px;
			@include mq{
				padding-top: 10px;
			}
		}
		&.has-error{
			margin-bottom: 22px;
			position: relative;
			.form-control{
				background: #f9ebeb;
			}
			&:after{
				content: "必須項目です";
				@include font-size(14);
				font-weight: 500;
				color: #cc223a;
				position: absolute;
				bottom: 0;
				left: 0;
				transform: translateY(100%);
				@include mq{
					@include font-size(13);
				}
			}
			.radio .checkmark{
				background-color: #f9ebeb;
			}
		}
	}

	&__dl{
		@include flex(0,start,wrap);
		margin-bottom: 40px;
		@include mq{
			margin-bottom: 20px;
		}
		&:nth-last-child(2){
			margin-bottom: clamp(3em,7vw,10em);
		}
		&--form{
			.contact-page{
				&__dt{
					@include mq(md-min){
						padding-top: 45px;
					}
				}
			}
		}
	}
	&__btn{
		background: rgb(32,68,119);
		background: linear-gradient(180deg, rgba(32,68,119,1) 0%, rgba(45,102,156,1) 100%);
		max-width: 350px;
		height: 80px;
		width: 100%;
		margin: 0 auto;
		cursor: pointer;
		outline: 0;
		border: none;
		color: #fff;
		font-family: $font-jp;
		@include font-size(22);
		font-weight: 500;
		@include flex(center, center);
		@include smooth-transition;
		@include mq{
			@include font-size(20); 
			max-width: 300px;
			height: 60px;
		}
		&:hover{
			opacity: 0.7;
		}
	}


	&__info{
		max-width: 845px;
		width: 100%;
		margin: 0 auto;
		border-radius: 5px;
		border: 3px solid #1f4073;
		padding: 40px 50px 36px;
		font-weight: bold;
		@include flex(0,start,wrap);
		@include mq(tb){
			padding: 20px 25px 36px;
		}
		@include mq{
			padding: 20px 15px;
			max-width: 400px;
		}
		&-left{
			width: calc(100% - 312px);
			position: relative;
			top: -8px;
			@include mq{
				width: 100%;
				margin-bottom: 20px;
			}
		}
		&-ttl{
			@include font-size(24);
			font-weight: 600;
			color: #1f4073;
			margin-bottom: 8px;
			letter-spacing: 0.5px;
			@include mq{
				@include font-size(20);
				letter-spacing: 0;
				text-align: center;
			}
		}
		&-dots{
			line-height: 1.2;
			li{
				position: relative;
				padding-left: 20px;
				margin-bottom: 15px;
				&:last-of-type{
					margin-bottom: 0;
				}
				@include p-before(10px, 10px){
					background: #1f4073;
					border-radius: 50%;
					top: 5px;
					left: 0;
				}
			}
		}
		&-right{
			width: 312px;
			background: rgba(#d6e1ec, 0.7);
			background-blend-mode: multiply;
			border-radius: 5px;
			padding: 20px 20px;
			text-align: center;
			@include mq{
				width: 100%;
				padding: 15px;
			}
		}
		&-label{
			display: block;
		}
		&-tel{
			text-align: center;
			display: block;
			img{
				height: 21px;
				width: auto;
			}
		}
	}

	&__thanks{
		text-align: center;
		margin-bottom: clamp(3em,8vw,7.4em);
		font-weight: 500;
		&-txt-lead{
			@include font-size(24);
			font-weight: 600;
			display: block;
			margin-bottom: 15px;
			color: #1f4073;
			@include mq{
				@include font-size(20);
				line-height: 1.5;
			}
		}
	}
}


.wpcf7-not-valid-tip{
	font-size: 1.4rem;
	margin-top: 10px;
}

.required, .any{
	width: 40px;
	height: 25px;
	color: #fff;
	background: #cc223a;
	@include flex(center, center);
	@include font-size(13);
	font-weight: 500;
	margin-left: 8px;
	@include mq{
		@include font-size(12);
		height: 20px;
	}
}


.any{
	background: #1f4073;
}

.radio{
	display: inline-block;
	position: relative;
	padding-left: 50px;
	cursor: pointer;
	user-select: none;
	@include smooth-transition;
	@include mq{
		padding-left: 40px;
	}
	&:hover{
		opacity: .7;
	}
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}
	.checkmark {
		position: absolute;
		top: -3px;
		left: 0;
		height: 40px;
		width: 40px;
		border-radius: 50%;
		background: #fff;
		@include mq{
			width: 30px;
			height: 30px;
			top: -1px;
		}
		@include p-after(16px, 16px){
			left: 12px;
			top: 11px;
			border-radius: 50%;
			background: #1f4073;
			transform: scale(0);
			@include smooth-transition;
			@include mq{
				width: 12px;
				height: 12px;
				left: 9px;
				top: 9px;
			}
		}
	}
	input:checked ~ .checkmark:after {
		transform: scale(1)
	}
}


.wpcf7-radio{
	display: inline-flex;
	flex-wrap: wrap;
    gap: 13px 40px;
	.wpcf7-list-item{
		display: inline-block;
		position: relative;
		padding-left: 50px;
		cursor: pointer;
		user-select: none;
		@include smooth-transition;
		margin-left: 0;
		@include mq{
			padding-left: 40px;
		}
		&:hover{
			opacity: .7;
		}
		input{
			position: absolute;
			opacity: 0;
			cursor: pointer;
			height: 0;
			width: 0;
		}
		&-label{
			cursor: pointer;
			font-weight: 700;
			@include p-before(40px, 40px){
				top: -3px;
				left: 0;
				border-radius: 50%;
				background: #fff;
				@include mq{
					width: 30px;
					height: 30px;
					top: -1px;
				}
			}
			@include p-after(16px, 16px){
				left: 12px;
				top: 9px;
				border-radius: 50%;
				background: #1f4073;
				transform: scale(0);
				@include smooth-transition;
				@include mq{
					width: 12px;
					height: 12px;
					left: 9px;
					top: 9px;
				}
			}
		}
		input:checked + span{
			&:after{
				transform: scale(1);
			}
		}
	}
}
.file-note{
	@include font-size(14);
	display: block;
	margin-bottom: 2px;
}

.file-upload{
	p{
		padding: 15px 20px;
		border-radius: 5px;
		background: #fff;
		@include flex(0, center);
		gap: 20px;
		position: relative;
		@include mq(sm) {
			gap: 10px;
		}
		@include mq(xs) {
			@include font-size(12);
		}
	}
	&:not(:last-child) {
		margin-bottom: 30px;
		@include mq {
			margin-bottom: 30px;
		}
	}
	
	label {
		@include flex(center, center);
		width: 100%;
		max-width: 185px;
		height: 40px;
		background: #fff;
		color: #1f4073;
		border: 1px solid #1f4073;
		border-radius: 4px;
		font-weight: 700;
		transition: 0.3s ease;
		cursor: pointer;
		@include mq {
			max-width: 120px;
			height: 38px;
		}
		@include mq(xs) {
			width: 120px;
			height: 40px;
		}
		@include hover {
			opacity: 0.7;
		}
	}
	input {
		opacity: 0;
		position: absolute;
		z-index: 10;
    	top: 21px;
	}
	> span:last-child {
		word-break: break-all;
		display: -webkit-box;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		line-height: 1.4;
		max-height: 45px;
		width: calc(100% - 185px);
		@include mq {
			max-height: 39px;
			width: calc(100% - 150px);
		}
	}
	.wpcf7-form-control-wrap{
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		.wpcf7-not-valid-tip{
			position: absolute;
    		bottom: -40px;
		}
	}
}

.wpcf7-response-output{
	display: none;
}
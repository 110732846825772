@use 'vars' as *;

.blog-page{
    position: relative;
    z-index: 2;
    padding: clamp(10em,20vw,25em) 0 120px;
    background: url(../img/blog/before-01.png) no-repeat 81% 3%/40.95% auto;
    @include mq{
        padding-bottom: 50px;
        background: url(../img/blog/before-01.png) no-repeat 81% 0/90% auto;
    }
    &__ttl{
        max-width: 564px;
        margin: 0 auto clamp(3em,10vw,12em);
        @include mq{
            max-width: 300px;
        }
    }


.blog-tabs{
    @include flex(0,start,wrap);
    background: rgba($color: #fff, $alpha: 0.6);
    padding: clamp(1.5em,4.5vw,7em) clamp(2.5em,7vw,9em);
    @include mq{
        border-top: 3px solid #1f4073;
    }
    &__content{
        width: calc(100% - 270px);
        display: none;
        padding-top: 1em;
        @include mq(tb){
            order: 1;
            width: 100%;
        }
        &.active{
            display: block;
        }
    }
    &__archive{
        width: 270px;
        position: sticky;
        top: 0;
        padding-top: 1em;
        @include mq(tb){
            width: 100%;
            order: 3;
        }
        &-ttl{
            width: 168px;
            margin-bottom: 20px;
        }
        &-list{
            padding: 37px 20px;
            border-top: 1px dashed #1f4073;
            @include mq{
                padding: 20px 15px 0;
            }
            li{
                margin-bottom: 12.5px;
                &:last-of-type{
                    margin-bottom: 0;
                }
                a{
                    @include font-size(18);
                    color: #1f4073;
                    display: inline-flex;
                    align-items: center;
                    letter-spacing: 1px;
                    span{
                        @include font-size(10);
                        margin-right: 5px;
                        position: relative;
                        top: -2px;
                    }
					&::before{
						content: "●";
						display: inline-block;
						@include font-size(10);
                        margin-right: 5px;
                        position: relative;
                        top: -2px;
					}
                }
            }
        }
    }
    .nums-page{
        width: 100%;
        @include mq(tb){
            order: 2;
            margin-bottom: 50px;
        }
    }
}

.journal-list{
    &--styles02{
        display: grid !important;
        grid-template-columns: 1fr 1fr;
        gap: 68px 75px;
        background: none;
        background: none;
        padding: 0;
        padding-right: clamp(3em,7vw,80px);
        margin-bottom: 66px;
        border: none;
        @include mq(xl){
            gap: 40px 40px;
        }
        @include mq(tb){
            padding-right: 0;
        }
        @include mq{
            gap: 30px;
            margin: 0 0 30px;
            width: 100%;
        }
        @include mq(sm){
            grid-template-columns: 1fr;
        }
    }
    &__item{
        margin-right: 0;
        width: 100%;
    }
    &__img{
        border-radius: 10px;
        overflow: hidden;
        border: 3px solid #1f4073;
        margin-bottom: 30px;
        @include mq{
            margin-bottom: 15px;
        }
    }
}

}

.box-modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    @include flex(center, start);
    overflow-y: auto;
    opacity: 0;
    visibility: hidden;
    z-index: 999;
    transition: 0.3s all;
    &.is-active{
        opacity: 1;
        visibility: visible;
    }
    &__close{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba($color: #1f4073, $alpha: 0.7);
    }
    &__wp{
        max-width: 1000px;
        border-radius: 20px;
        border: 3px solid #1f4073;
        padding: clamp(2.5em,6vw,8em) clamp(2.5em,8vw,10em);
        margin: 50px auto;
        position: relative;
        z-index: 2;
        background: #fff;
        @include mq{
            width: 90%;
            margin: 30px 0;
        }
    }
    &__img{
        @include aspect-ratio(800, 518);
        border-radius: 10px;
        border: 3px solid #1f4073;
        margin-bottom: 42px;
        @include mq{
            margin-bottom: 15px;
        }
        img{
            width: 100%;
        }
    }
    &__time{
		display: block;
		color: #cc223a;
		font-weight: 600;
		margin-bottom: 13px;
		@include font-size(18);
		font-feature-settings: "palt";
		@include mq(xl){
			margin-bottom: 10px;
			@include font-size(17);
		}
		@include mq(){
			margin-bottom: 8px;
			@include font-size(16);
		}
	}
	&__ttl{
		color: #111;
		font-weight: 600;
        margin-bottom: 17px;
		@include font-size(20);
		@include mq(xl){
			margin-bottom: 10px;
			@include font-size(18);
		}
		@include mq(){
			margin-bottom: 16px;
			@include font-size(16);
		}
	}
    &__ct{
        padding: 0 10px;
    }
    &__btn{
        width: 50px;
        height: 45px;
        display: block;
        margin: 50px auto 0;
        background: url(../img/blog/btn-close.png) no-repeat center/100%;
        border: none;
        outline: 0;
        cursor: pointer;
        @include mq{
            margin-top: 30px;
        }
    }
    &__row{
        @include flex(between, center, wrap);
        border-top: 2px solid #08466d;
        padding-top: 20px;
        margin-bottom: 60px;
        margin-top: 50px;
        @include mq{
            margin-top: 30px;
            margin-bottom: 45px;
        }
    }
    &__btns{
        width: 310px;
        @include flex(start, center, wrap);
        @include mq{
            width: 100%;
        }
        &-item{
            &:first-of-type{
                margin-right: 10px;
            }
            a,.wp_ulike_general_class{
                @include font-size(14);
                font-weight: 600;
                box-shadow: none !important;
                padding: 0 !important;
                display: flex !important;
                justify-content: center;
                align-items: center;
                height: 45px;
                text-align: center;
                width: 150px !important;
                background: #dae3e9 !important;
                border: none !important;
                color: #1f4073 !important;
                border-radius: 5px !important;
            }
            a{
                position: relative;
                padding-left: 20px !important;
                &:hover{
                    opacity: 1;
                }
                &::before{
                    content: "";
                    display: block;
                    background: url("../img/common/comment.png") no-repeat 0 0 / 100% auto;
                    width: 20px;
                    height: 18px;
                    position: absolute;
                    top: 50%;
                    left: 15px;
                    -webkit-transform: translateY(-50%);
                    -ms-transform: translateY(-50%);
                    transform: translateY(-50%);
                }
            }
            .wp_ulike_general_class{
                position: relative;                
                button{
                    position: absolute !important;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    padding: 0 !important;
                    &::before{
                        content: "いいね";
                        color: #1f4073 !important;
                    }
                    &::after{
                        left: 25px !important;
                        background: url(../img/common/heart-wh.png) no-repeat 0 0 / 100% auto !important;
                        width: 20px !important;
                        height: 19px !important;
                    }
                    &.wp_ulike_btn_is_active{
                        &::after{
                            background-image: url(../img/common/heart.png) !important;
                            filter: none !important;
                        }
                    }
                }
                .count-box{
                    position: absolute !important;
                    right: 10px;
                    top: 50%;
                    -webkit-transform: translateY(-50%);
                    -ms-transform: translateY(-50%);
                    transform: translateY(-50%);
                    color: #1f4073 !important;
                }
            }
        }
    }
    &__sns{
        width: calc(100% - 310px);
        @include flex(end, center, wrap);
        @include mq{
            width: 100%;
            justify-content: flex-start;
            margin-top: 15px;
        }
        &-ttl{
            @include font-size(16);
            font-weight: 600;
            color: #08466d;
            border-right: 1px solid #08466d;
            padding-right: 20px;
            @include mq{
                @include font-size(14);
            }
        }
        &-item{
            margin-left: 20px;
        }
    }
}

.comments{
    .comment-form-comment{
        label{
            display: none;
        }
    }
    &__ttl,
    .comment-reply-title{
        position: relative;
        @include font-size(18);
        font-weight: 600;
        padding-bottom: 12px;
        padding-left: 45px;
        @include mq{
            @include font-size(16);
        }
        &::before{
            content: "";
            display: block;
            background: url("../img/common/comment.png") no-repeat 0 0 / 100% auto;
            width: 24px;
            height: 21px;
            position: absolute;
            top: 0;
            left: 0;
        }
        &::after{
            content: "";
            background: #08466d;
            opacity: 0.5;
            width: 100%;
            height: 1px;
            position: absolute;
            left: 0;
            bottom: 0;
        }
        &-mb{
            margin-bottom: 30px;
        }
    }
    .comment-reply-title{
        margin-bottom: 30px;
    }
    #respond{
        .comment-reply-title{
            display: none;
        }
    }
    #commentform{
        p{
            color: #08466d;
            @include font-size(16);
            font-weight: 600;
            margin-bottom: 10px;
            @include mq{
                @include font-size(14);
            }
        }
        .comment-form-comment,
        .comment-form-author{
            margin-bottom: 40px;
        }
        .comment-form-author{
            label{
                display: block;
                margin-bottom: 10px; 
            }
        }
        textarea,input[type="text"]{
            display: block;
            width: 100%;
            height: 70px;
            background: white;
            font-weight: 500;
            padding: 10px 15px;
            @include font-size(16);
            border-radius: 5px;
            border: 1px solid #bbc5d5;
            @include mq{
                @include font-size(14);
                height: 50px;
            }
        }
        textarea{
            padding: 20px;
            height: 300px;
            resize: vertical;
            @include mq{
                height: 200px;
            }
        }
        .comment-form-cookies-consent{
            display: none;
        }
        .form-submit{
            input{
                cursor: pointer;
                display: block;
                width: 300px;
                height: 80px;
                @include font-size(18);
                color: #FFF;
                text-align: left;
                padding: 0 0 0 30px;
                background: rgb(37,81,133);
                background: url("../img/common/arrow.png") no-repeat right 30px center / 50px auto,linear-gradient(180deg, rgba(37,81,133,1) 35%, rgba(52,119,172,1) 100%) ;
                border: none;
                margin: 0 auto;
                @include mq{
                    @include font-size(14);
                    height: 65px;
                    max-width: 100%;
                }
            }
        }
        .logged-in-as{
            display: none;
        }
    }
    &__list{
        margin-bottom: 60px;
        .comment-body{
            position: relative;
        }
        .comment-author{
            display: inline-block;
            @include font-size(16);
            font-weight: 600;
            margin-right: 25px;
            padding-right: 25px;
            border-right: #a6a6a6 2px solid;
            line-height: 1;
            @include mq{
                padding-right: 10px;
                margin-right: 10px;
            }
            .says{
                display: none;
            }
            .fn{
               @include font-size(16);
                font-weight: 600;
                @include mq{
                    @include font-size(12);
                }
                a{
                    color: #111;
                    pointer-events: none;
                }
            }
        }
        .comment-meta{
            display: inline-block;
            line-height: 1;
            a{
                color: #111;
                pointer-events: none;
                @include font-size(16);
                font-weight: 600;
                @include mq{
                    @include font-size(12);
                }
            }
        }
        &>li{
            position: relative;
            padding: 30px 0 60px;
            border-bottom: 1px solid #dae3e9;
            &:last-of-type{
                margin-bottom: 0;
                border-bottom: none;
            }
            p{
                margin-top: 15px;
                @include font-size(16);
                line-height: 2;
                @include mq{
                    @include font-size(13);
                    line-height: 1.6;
                }
            }
        }
        .children{
            margin-top: 45px;
            margin-left: 25px;
            padding-left: 20px;
            border-left: 2px solid #6b90a7;
            @include mq{
                margin-left: 10px;
                padding-left: 10px;
            }
            li{
                position: relative;
                padding-bottom: 60px;
            }
        }
        .wpulike{
            position: absolute;
            right: 80px;
            bottom: -40px;
            display: inline-block !important;
            padding: 0 !important;
            vertical-align: middle;
        }
        .wp_ulike_general_class{
            border: none !important;
            padding: 0 !important;
            box-shadow: none !important;
            button{
                color: #1f4073;
                font-weight: 600;
                @include font-size(14);
                &::after{
                    background: url(../img/common/heart-off.png) no-repeat 0 0 / 100% auto !important;
                    width: 15px !important;
                    height: 13px !important;
                }
                &.wp_ulike_btn_is_active{
                    &::after{
                        background-image: url(../img/common/heart.png) !important;
                        filter: none !important;
                    }
                }
            }
        }
        .reply{
            position: absolute;
            right: 0;
            bottom: -30px;
            display: inline-block;
            vertical-align: middle;            
            margin-left: 25px;
            a{
                position: relative;
                padding-left: 25px;
                color: #1f4073;
                font-weight: 600;
                @include font-size(14);
                &::before{
                    content: "";
                    display: block;
                    background: url("../img/common/res.png") no-repeat 0 0 / 100% auto;
                    width: 15px;
                    height: 11px;
                    position: absolute;
                    top: 50%;
                    margin-top: -5px;
                    left: 0;
                }
            }
        }
    }
}